<template>
    <Suspense>
        <template #default>
            <svg viewBox="191.5932 0 720 720" preserveAspectRatio="xMinYMin slice" easing>
                <defs>
                    <clipPath :id="config?.id" v-for="config in svgconfig">
                        <path :d="config.path" fill="none" />
                    </clipPath>
                </defs>
                <g :clip-path="`url(#${config?.id})`" v-for="config in svgconfig" :key="config?.id">
                    <image :transform="`translate(${config.coords.x} ${config.coords.y}) scale(0.8)`" width="512" height="512" :xlink:href="config?.asset?.url" />
                </g>
                <rect x="0" width="0" height="720" fill="none" />
            </svg>
        </template>
        <template #fallback>
            <svg :viewBox="svgviewbox">
                <slot></slot>
                <foreignObject width="100%" height="100%" :clip-path="'url(#' + clipPathId + ')'">
                    <img ref="asset" :src="fpo" :alt="alt" loading="lazy" lowres easing />
                </foreignObject>
            </svg>
        </template>
    </Suspense>
</template>

<script>
import { ref, computed, watch, onBeforeMount } from 'vue';
import { useStore } from '@/javascript/lib/store'
import { getAxiosInstance, axioscacheconfig } from '@/javascript/lib/api'
import FPOImage from '@/assets/fpo.svg?url'
import { uuid } from '@/javascript/lib/utils.js'

export default {
    name: "FourMask",
    components: {},
    props: {
        alt: {
            type: String,
            default() {
                return null
            }
        },
        config: {
            type: Object,
            default() {
                return null
            }
        },
        svg: {
            type: Boolean,
            default() {
                return false
            }
        },
        clipPathId: {
            type: String,
            required: true
        },
    },
    setup(props) {
        const data          = ref(null)
        const store         = useStore()
        const loaded        = ref(false)
        const sources       = ref([])
        const svgviewbox    = ref(null)
        const svgconfig     = ref([])
        const api           = getAxiosInstance()
        const asset         = ref(null)
        const root          = ref(null)
        const fpo           = computed(() => {
            const uri = new URL(FPOImage, import.meta.url)
            return uri?.toString()
        })

        const srcset = computed(() => {
            const srcs = []
            if (!!sources.value?.length) {
                sources.value.map( v => {
                    const s = `${store.baseurl}${v.image}`
                    srcs.push(`${s} ${v.breakpointatt}w`)}
                )
            }
            return srcs.join(', ')
        })

        watch(sources, (newvalue, oldvalue) => {
            if(newvalue?.length) {
                const requests = newvalue.map( v => `${store.baseurl}${v.image}`)
                api.all(requests).then(responses => {
                    loaded.value = (requests?.length === responses?.length)
                }).catch(error => console.log(error.response))

                svgviewbox.value = "0 0 720 720"
            }
        })

        watch(asset, (newval, oldval) => {
            if (newval instanceof HTMLElement) {
                newval.addEventListener('load', () => {
                    store.observe(newval, (e, observer) => {
                        observer.unobserve(newval)
                        newval.removeAttribute('lowres')
                    })
                })
            }
        })

        // trigger the callback function when the data(proxy object) is updated
        watch(data, (newval, oldval) => {
            if(newval) {
                const coords = [
                    {x: 0, y: 522.86},
                    {x: 467.46, y: 522.86},
                    {x: 233.88, y: 287.87},
                    {x: 0, y: 53.28}
                ]

                const paths = [
                    'm380.59,720c1.95-6.77.26-14.36-5.07-19.69l-171.59-171.59c-3.44-3.44-7.83-5.37-12.33-5.77v197.05h189Z',
                    'm844.8,700.31l-171.59-171.59c-7.81-7.81-20.47-7.81-28.28,0l-171.59,171.59c-5.33,5.33-7.02,12.92-5.07,19.69h381.61c1.95-6.77.26-14.36-5.07-19.69Z',
                    'm239.74,493.6l171.59,171.59c7.81,7.81,20.47,7.81,28.28,0l171.59-171.59c7.81-7.81,7.81-20.47,0-28.28l-171.59-171.59c-7.81-7.81-20.47-7.81-28.28,0l-171.59,171.59c-7.81,7.81-7.81,20.47,0,28.28Z',
                    'm203.63,430.61l171.59-171.59c7.81-7.81,7.81-20.47,0-28.28L203.63,59.14c-3.37-3.37-7.64-5.28-12.03-5.74v382.95c4.39-.46,8.67-2.37,12.03-5.74Z'
                ]

                data.value?.assets.map((v, i) => {
                    const url = `${store.baseurl}${v.url}`
                    const image = {...v}

                    // updating url property with the extracted URL.
                    image.url = url
                    svgconfig.value = [ // svgconfig define the svg properties
                        ...svgconfig.value, // spreading the svgconfig array to a new array
                        {
                            id: `clippath-${uuid()}`,
                            path: paths[i],
                            coords: coords[i],
                            asset: image // The asset object with its properties, including the updated url.
                        }
                    ]
                })
            }
        })

        onBeforeMount(() => {
            if((props.config?.constructor !== Object)) {
                data.value = JSON.parse(props.config)
            }else{
                data.value = props.config
            }
        })

        return {
            data,
            root,
            asset,
            fpo,
            loaded,
            srcset,
            svgviewbox,
            svgconfig
        }
    }
}
</script>
