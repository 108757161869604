<template>
    <Suspense>
        <template #default>
            <svg :viewBox="svgviewbox">
                <slot></slot>
                <foreignObject width="100%" height="100%" :clip-path="'url(#' + clipPathId + ')'">
                    <img ref="asset" :src="data?.url" :srcset="srcset" :alt="alt" loading="lazy" lowres easing />
                </foreignObject>
            </svg>
        </template>
        <template #fallback>
            <svg :viewBox="svgviewbox">
                <slot></slot>
                <foreignObject width="100%" height="100%" :clip-path="'url(#' + clipPathId + ')'">
                    <img ref="asset" :src="fpo" :alt="alt" loading="lazy" lowres easing />
                </foreignObject>
            </svg>
        </template>
    </Suspense>
</template>

<script>
import { ref, computed, watch, onBeforeMount } from 'vue';
import { useStore } from '@/javascript/lib/store'
import { getAxiosInstance, axioscacheconfig } from '@/javascript/lib/api'
import FPOImage from '@/assets/fpo.svg?url'

export default {
    name: "ImageMask",
    components: {},
    props: {
        alt: {
            type: String,
            default() {
                return null
            }
        },
        config: {
            type: Object,
            default() {
                return null
            }
        },
        svg: {
            type: Boolean,
            default() {
                return false
            }
        },
        clipPathId: {
            type: String,
            required: true
        },
    },
    setup(props) {
        const data          = ref(null)
        const store         = useStore()
        const loaded        = ref(false)
        const sources       = ref([])
        const svgviewbox    = ref(null)
        const api           = getAxiosInstance()
        const asset         = ref(null)
        const root          = ref(null)
        const fpo           = computed(() => {
            const uri = new URL(FPOImage, import.meta.url)
            return uri?.toString()
        })

        const srcset = computed(() => {
            const srcs = []
            if (!!sources.value?.length) {
                sources.value.map( v => {
                    const s = (store.mode == 'local') ? `https://dev.discoversurreybc.com${v.image}` : v.image
                    srcs.push(`${s} ${v.breakpointatt}w`)}
                )
            }
            return srcs.join(', ')
        })

        watch(sources, (newvalue, oldvalue) => {
            if(newvalue?.length) {
                const requests = newvalue.map( v => (store.mode == 'local') ? `https://dev.discoversurreybc.com${v.image}` : v.image)
                api.all(requests).then(responses => {
                    loaded.value = (requests?.length === responses?.length)
                }).catch(error => console.log(error.response))

                svgviewbox.value = "0 0 1000 1000" // "0 0 width height" or "0 0 width width"
            }
        })

        watch(asset, (newval, oldval) => {
            if (newval instanceof HTMLElement) {
                newval.addEventListener('load', () => {
                    store.observe(newval, (e, observer) => {
                        observer.unobserve(newval)
                        newval.removeAttribute('lowres')
                    })
                })
            }
        })

        onBeforeMount(() => {
            if((props.config?.constructor !== Object)) {
                data.value = JSON.parse(props.config)
            }else{
                data.value = props.config
            }
            sources.value = data.value?.sources?.sort( (a, b) => a.breakpointatt - b.breakpointatt)
        })

        return {
            data,
            root,
            asset,
            fpo,
            loaded,
            srcset,
            svgviewbox
        }
    }
}
</script>
