// CSS
import '@/styles/main.scss'

//JS Application
import { createApp, defineCustomElement } from 'vue'
import { createPinia } from 'pinia'
import App from './App.vue'
import Appbar from '@/components/Appbar.ce.vue'
import AppFooter from '@/components/AppFooter.ce.vue'
import Address from '@/components/Address.ce.vue'
import UIElementalPageHeader from '@/components/UIElementalPageHeader.ce.vue'
import UIElementalMosaic from '@/components/UIElementalMosaic.ce.vue'
import UIElementalTriptych from '@/components/UIElementalTriptych.ce.vue'
import UIElementalCarousel from '@/components/UIElementalCarousel.ce.vue'
import UIElementalContent from '@/components/UIElementalContent.ce.vue'
import UIElementalTaxonomyMosaic from '@/components/UIElementalTaxonomyMosaic.ce.vue'
import UIElementalGallery from '@/components/UIElementalGallery.ce.vue'
import UIElementalImage from '@/components/UIElementalImage.ce.vue'
import UIElementalHeading from '@/components/UIElementalHeading.ce.vue'
import UIElementalBlogTitle from '@/components/UIElementalBlogTitle.ce.vue'
import UIElementalCrowdriff from '@/components/UIElementalCrowdriff.ce.vue'
import UIElementalEvents from '@/components/UIElementalEvents.ce.vue'
import UIElementalEventsList from '@/components/UIElementalEventsList.ce.vue'
import UIElementalEventShowcase from '@/components/UIElementalEventShowcase.ce.vue'
import UIElementalCallout from '@/components/UIElementalCallout.ce.vue'
import UIElementalExperience from '@/components/UIElementalExperience.ce.vue'
import UIElementalStories from '@/components/UIElementalStories.ce.vue'
import UIElementalStoriesList from '@/components/UIElementalStoriesList.ce.vue'
import UIElementalRepeater from '@/components/UIElementalRepeater.ce.vue'
import UIElementalCTAGroup from '@/components/UIElementalCTAGroup.ce.vue'
import UIElementalTaxonomyMap from '@/components/UIElementalTaxonomyMap.ce.vue'
import UIElementalFeatureMap from '@/components/UIElementalFeatureMap.ce.vue'
import UIElementalBanner from '@/components/UIElementalBanner.ce.vue'
import UIElementalGrid from '@/components/UIElementalGrid.ce.vue'
import UIElementalTaxonomyList from '@/components/UIElementalTaxonomyList.ce.vue'
import UIElementalPartnerList from '@/components/UIElementalPartnerList.ce.vue'
import UIElementalPartnerMap from '@/components/UIElementalPartnerMap.ce.vue'

import Umbra from './javascript/modules/umbra'

// Umbra singleton
const U = ('__umbrastate__' in window) ? new Umbra({state:{
    active:true,
    ...window['__umbrastate__']
}}) : new Umbra({state:{
    active:true
}})

/**
 * @link https://vuejs.org/guide/extras/web-components.html#building-custom-elements-with-vue
 */
export function register() {
    customElements.define('rs-appbar',                      defineCustomElement(Appbar))
    customElements.define('rs-appfooter',                   defineCustomElement(AppFooter))
    customElements.define('rs-address',                     defineCustomElement(Address))
    customElements.define('rs-uielementalheader',           defineCustomElement(UIElementalPageHeader))
    customElements.define('rs-uielementalmosaic',           defineCustomElement(UIElementalMosaic))
    customElements.define('rs-uielementaltriptych',         defineCustomElement(UIElementalTriptych))
    customElements.define('rs-uielementalcarousel',         defineCustomElement(UIElementalCarousel))
    customElements.define('rs-uielementalcontent',          defineCustomElement(UIElementalContent))
    customElements.define('rs-uielementalgallery',          defineCustomElement(UIElementalGallery))
    customElements.define('rs-uielementalimage',            defineCustomElement(UIElementalImage))
    customElements.define('rs-uielementalheading',          defineCustomElement(UIElementalHeading))
    customElements.define('rs-uielementalblogtitle',        defineCustomElement(UIElementalBlogTitle))
    customElements.define('rs-uielementalcrowdriff',        defineCustomElement(UIElementalCrowdriff))
    customElements.define('rs-uielementalevents',           defineCustomElement(UIElementalEvents))
    customElements.define('rs-uielementaleventslist',       defineCustomElement(UIElementalEventsList))
    customElements.define('rs-uielementaleventshowcase',    defineCustomElement(UIElementalEventShowcase))
    customElements.define('rs-uielementalcallout',          defineCustomElement(UIElementalCallout))
    customElements.define('rs-uielementalexperience',       defineCustomElement(UIElementalExperience))
    customElements.define('rs-uielementaltaxonomymosaic',   defineCustomElement(UIElementalTaxonomyMosaic))
    customElements.define('rs-uielementalstories',          defineCustomElement(UIElementalStories))
    customElements.define('rs-uielementalstorieslist',      defineCustomElement(UIElementalStoriesList))
    customElements.define('rs-uielementalrepeater',         defineCustomElement(UIElementalRepeater))
    customElements.define('rs-uielementalctagroup',         defineCustomElement(UIElementalCTAGroup))
    customElements.define('rs-uielementaltaxonomymap',      defineCustomElement(UIElementalTaxonomyMap))
    customElements.define('rs-uielementalfeaturemap',       defineCustomElement(UIElementalFeatureMap))
    customElements.define('rs-uielementalbanner',           defineCustomElement(UIElementalBanner))
    customElements.define('rs-uielementaltaxonomylist',     defineCustomElement(UIElementalTaxonomyList))
    customElements.define('rs-uielementalpartnerlist',      defineCustomElement(UIElementalPartnerList))
    customElements.define('rs-uielementalpartnermap',       defineCustomElement(UIElementalPartnerMap))
    customElements.define('rs-uielementalgrid',             defineCustomElement(UIElementalGrid))
}

const app = createApp(App)
app.use(createPinia())
app.mount('#App')

register();
