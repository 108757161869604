<template>
    <div ref="root" class="mapinfowindow mapinfowindow--root">
        <div class="mapinfowindow--wrapper">
            <div v-if="data?.asset.mm" class="mapinfowindow--media">
                <div class="mapinfowindow--content">
                    <a :href="data?.url" class="media-wrapper">
                        <img :src="data?.asset.sm" loading="lazy" :data-deferred-src="data?.asset.mm" :alt="data?.title" />
                    </a>
                </div>
            </div>
            <div class="mapinfowindow--address">
                <div class="mapinfowindow--content">
                    <a v-if="data?.url" :href="data?.link" target="_blank" class="address--name" scale="subtitle-lg" branded v-html="data?.title"/>
                    <div v-if="data?.address" class="address--street" v-html="data?.address"/>
                    <div v-if="data?.city" class="address--city">{{ data?.city }}, BC</div>
                    <div v-if="data?.postalCode" class="address--postalcode" v-html="data?.postalCode"/>
                </div>
            </div>
            <div v-if="data?.url" class="mapinfowindow--actions">
                <div class="mapinfowindow--content">
                    <a :href="data?.url" easing more scale="button">Learn More</a>
                    <a :href="data?.url" rel="external nofollow noreferrer noopner" target="_blank" easing scale="button">Visit</a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ref, onBeforeMount } from 'vue';

export default {
    name: "InfoWindowAdvanced",
    components: {},
    props: {
        config: {
            type: Object,
            default() {
                return null
            }
        }
    },
    setup(props) {
        const root = ref(null)
        const data = ref(null)

        onBeforeMount(() => {
            data.value = props.config
        })

        return {
            root,
            data
        }
    }
}
</script>

<style lang="scss">
@include foundation();
</style>
