import { ref, computed, onBeforeMount } from 'vue'
import { defineStore } from "pinia"

export const useStore = defineStore('store', () => {
    const observers = ref(new Set())
    const observer  = ref(null)
    const observe   = (element, callback) => {
        observers.value.add(element)
        if (observer.value && observer.value instanceof IntersectionObserver) {
            ([...observers.value.values()])?.map( (e) => {
                observer?.value.observe(e)
                if (callback instanceof Function) {
                    callback(e, observer.value)
                }
            })
        }
    }
    const baseurl = (['local', 'localhost'].includes(__umbrastate__.mode)) ? `https://dev.discoversurreybc.com` : ''
    const selectedmarker = ref(null)
    const svgmapdata = ref(null)
    const menuactive = ref(false)
    const mapstate = ref({
        data:           null,
        loading:        false,
        infoWindow:     null,
        activemarkers:  null,
        markers:        null,
        markerclick:    null,
        selection:      null,
        data:           null,
        dataset:        null,
        search:         null,
        searchcomplete: null,
        bounds:         null,
        infoWindowClick:     null
    })
    const dialoginstance = ref(null)
    const appbartype = ref(null)
    const mapstyles = [
        {
            "featureType": "administrative",
            "elementType": "labels.text.fill",
            "stylers": [
                {
                    "color": "#444444"
                }
            ]
        },
        {
            "featureType": "landscape",
            "elementType": "all",
            "stylers": [
                {
                    "color": "#CEDEBA"
                }
            ]
        },
        {
            "featureType": "landscape.natural",
            "elementType": "labels.text.fill",
            "stylers": [
                {
                    "color": "#414141"
                }
            ]
        },
        {
            "featureType": "landscape.natural.terrain",
            "elementType": "geometry.fill",
            "stylers": [
                {
                    "visibility": "on"
                },
                {
                    "color": "#e0ebe9"
                }
            ]
        },
        {
            "featureType": "poi",
            "elementType": "all",
            "stylers": [
                {
                    "visibility": "off"
                }
            ]
        },
        {
            "featureType": "road",
            "elementType": "all",
            "stylers": [
                // {
                //     "saturation": -100
                // },
                // {
                //     "lightness": 45
                // }
                {
                    "color": "#FBF8E4"
                }
            ]
        },
        {
            "featureType": "road.highway",
            "elementType": "all",
            "stylers": [
                {
                    "visibility": "simplified"
                }
            ]
        },
        {
            "featureType": "road.arterial",
            "elementType": "labels.icon",
            "stylers": [
                {
                    "visibility": "off"
                }
            ]
        },
        {
            "featureType": "transit",
            "elementType": "all",
            "stylers": [
                {
                    "visibility": "off"
                }
            ]
        },
        {
            "featureType": "water",
            "elementType": "all",
            "stylers": [
                {
                    "color": "#46bcec"
                },
                {
                    "visibility": "on"
                }
            ]
        },
        {
            "featureType": "water",
            "elementType": "geometry.fill",
            "stylers": [
                {
                    "color": "#96D7E0"
                }
            ]
        },
        {
            "featureType": "water",
            "elementType": "labels.text.fill",
            "stylers": [
                {
                    "color": "#ffffff"
                }
            ]
        }
    ]
    return {
        baseurl,
        observer, observers, observe,
        ...window?.__umbrastate__ ?? {},
        selectedmarker,
        svgmapdata,
        menuactive,
        mapstate,
        dialoginstance,
        appbartype,
        mapstyles
    }
})
