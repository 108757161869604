<template>
    <svg id="icon-search" v-bind="$attrs" :="iconprops" viewBox="0 0 48 48">
        <path :fill="clr" :stroke="clr" stroke-width="1" d="m39.2,42l-12.6-12.6c-1,.8-2.15,1.433-3.45,1.9s-2.683.7-4.15.7c-3.633,0-6.708-1.258-9.225-3.775s-3.775-5.592-3.775-9.225,1.258-6.708,3.775-9.225,5.592-3.775,9.225-3.775,6.708,1.258,9.225,3.775,3.775,5.592,3.775,9.225c0,1.467-.233,2.85-.7,4.15s-1.1,2.45-1.9,3.45l12.6,12.6-2.8,2.8Zm-20.2-14c2.5,0,4.625-.875,6.375-2.625s2.625-3.875,2.625-6.375-.875-4.625-2.625-6.375-3.875-2.625-6.375-2.625-4.625.875-6.375,2.625-2.625,3.875-2.625,6.375.875,4.625,2.625,6.375,3.875,2.625,6.375,2.625Z"/>
        <rect width="48" height="48" fill="none" />
    </svg>
</template>

<script>
import { ref, computed, watch } from 'vue'
import { useStore } from '@/javascript/lib/store'

export default {
    name: "IconSearch",
    props: {
        config: {
            type: Object,
            default() {
                return null
            }
        }
    },
    setup(props) {
        const data  = ref(null)
        const clr   = ref('#e68346')
        const store = useStore()
        const iconprops = ref(null)

        watch(() => store.menuactive, (n, o) => {
            iconprops.value = (store?.menuactive) ? {close: ''} : null
        })

        return {
            data,
            clr,
            iconprops
        }
    }
}
</script>
