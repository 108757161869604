<template>
    <Suspense>
        <template #default>
            <picture ref="root" v-if="data?.sources?.length && loaded" :asset-id="data?.id" :title="data?.title" :="$attrs">
                <source :srcset="srcset" :type="item?.mimetype">
                <img ref="asset" :src="data?.url ?? data?.thumbnail" :alt="alt" loading="lazy" lowres easing>
            </picture>
            <picture ref="root" v-else :asset-id="data?.id" :title="data?.title" :="$attrs">
                <source :srcset="fpo" :media="`(min-width): 300px)`" type="text/svg">
                <img :src="fpo" loading="lazy" easing>
            </picture>
        </template>
        <template #fallback>
            <picture ref="root" :="$attrs">
                <source :srcset="fpo" :media="`(min-width): 300px)`" type="text/svg">
                <img :src="fpo" loading="lazy" easing>
            </picture>
        </template>
    </Suspense>
</template>

<script>
import { ref, computed, watch, onBeforeMount } from 'vue';
import { useStore } from '@/javascript/lib/store'
import { getAxiosInstance, axioscacheconfig } from '@/javascript/lib/api'
import FPOImage from '@/assets/fpo.svg?url'

export default {
    name: "Picture",
    components: {},
    props: {
        alt: {
            type: String,
            default() {
                return null
            }
        },
        config: {
            type: Object,
            default() {
                return null
            }
        },
        clipPathId: {
            type: String,
            required: true
        },
    },
    setup(props) {
        const data          = ref(null)
        const store         = useStore()
        const loaded        = ref(false)
        const sources       = ref([])
        const api           = getAxiosInstance()
        const asset         = ref(null)
        const root          = ref(null)
        const fpo           = computed(() => {
            const uri = new URL(FPOImage, import.meta.url)
            return uri?.toString()
        })

        const srcset = computed(() => {
            const srcs = []
            if (!!sources.value?.length) {
                sources.value.map( v => {
                    const s = `${store.baseurl}${v.image}`
                    srcs.push(`${s} ${v.breakpointatt}w`)}
                )
            }
            return srcs.join(', ')
        })

        watch(sources, (newvalue, oldvalue) => {
            if(newvalue?.length) {
                const requests = newvalue.map( v => `${store.baseurl}${v.image}`)
                api.all(requests).then(responses => {
                    loaded.value = (requests?.length === responses?.length)
                }).catch(error => console.log(error.response))
            }
        })

        watch(asset, (newval, oldval) => {
            if (newval instanceof HTMLElement) {
                newval.addEventListener('load', () => {
                    store.observe(newval, (e, observer) => {
                        observer.unobserve(newval)
                        newval.removeAttribute('lowres')
                    })
                })
            }
        })

        watch(data, (newval, oldval) => {
            if(newval) {
                data.value.url = `${store.baseurl}${data.value?.url}`
            }
        })

        /**
         * Adding a watch on props.config allows for reactive props when re-using or re-populating elements
         */
        watch(() => props.config, (newval, oldval) => {
            if(newval) {
                data.value      = props.config
                data.value.url  = `${store.baseurl}${data.value?.url}`
                sources.value   = data.value?.sources?.sort( (a, b) => a.breakpointatt - b.breakpointatt)
            }
        })

        onBeforeMount(() => {
            if((props.config?.constructor !== Object)) {
                data.value = JSON.parse(props.config)
            }else{
                data.value = props.config
            }
            sources.value = data.value?.sources?.sort( (a, b) => a.breakpointatt - b.breakpointatt)
        })

        return {
            data,
            root,
            asset,
            fpo,
            loaded,
            srcset
        }
    }
}
</script>
