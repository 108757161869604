<template>
    <Suspense>
        <template #default>
            <rs-container ref="root" rs-elemental="uielementalpartnerlist" :="layoutprops">
                <rs-grid  xxs-1 sm-10 xl-12>
                    <rs-griditem span-xs-1 span-sm-12 filtercontainer v-if="showfilters">
                        <form filters @change.prevent.stop="filterhandler">
                            <label for="categoryfilter">
                                <MDText tag="span" v-if="!!label" :label="label" scale="subtitle-lg" branded />
                                <select id="categoryfilter" name="regions" rounded easing>
                                    <option value="All" v-text="`All`" />
                                    <option v-for="(item, index) in regions" :value="item" v-text="item" />
                                </select>
                                <select id="categoryfilter" name="terms" rounded easing>
                                    <option value="All" v-text="`All`" />
                                    <option v-for="(item, index) in categories" :value="item" v-text="item" />
                                </select>
                            </label>
                        </form>
                    </rs-griditem>
                    <template v-if="!!partners?.length">
                        <Partner v-for="(item, index) in partnerlist" :id="`partner-${item?.props?.id}`" :key="index" v-memo="partnerlist" :config="item"></Partner>
                    </template>
                    <template v-if="loading">
                        <MDSkeleton aspect-16-9 error="true" spin />
                    </template>
                </rs-grid>
            </rs-container>
        </template>
        <template #fallback>
            <MDSkeleton aspect-16-9>
                <MDBlocker active="true" cursor="progress" type="default" />
            </MDSkeleton>
        </template>
    </Suspense>
</template>

<script>
import { ref, computed, watch, onBeforeMount } from 'vue';
import { useStore } from '@/javascript/lib/store'
import { useWrappedString , useComponentdata, useNormalizeComponentProps, useNormalizeContentProps } from '@/javascript/lib/composables'
import MDButton from '@/components/MDButton.ce.vue'
import MDBlocker from '@/components/MDBlocker.vue'
import MDText from '@/components/MDText.vue'
import MDSkeleton from '@/components/MDSkeleton.ce.vue'
import Partner from '@/components/Partner.vue'
import TrieSearch from 'trie-search'

export default {
    name: "UIElementalPartnerList",
    components: {MDButton, MDSkeleton, MDBlocker, MDText, Partner},
    props: {
        info: {
            type: String,
            default() {
                return null
            }
        },
        config: {
            type: Object,
            default() {
                return null
            }
        }
    },
    setup(props) {
        const root              = ref(null)
        const store             = useStore()
        const data              = ref(null)
        const layoutprops       = ref(null)
        const contentprops      = ref(null)
        const partners          = ref([])
        const partnerlist       = ref([])
        const term              = ref(null)
        const loading           = ref(true)
        const regions           = ref(null)
        const categories        = ref(null)
        const fitlerfields      = ref(null)
        const dataset           = ref(null)
        const label             = ref(null)
        const links             = ref(null)
        const delay             = ref(320)
        const showfilters       = computed(() => {
            return !!(parseInt(contentprops.value?.filters, 10))
        })
        const showcontent       = computed(() => data.value?.display?.displaycontent)
        const showtitle         = computed(() => data.value?.display?.showtitle)
        const showlinks         = computed(() => data.value?.display?.displaylinks && data.value?.links?.length)
        const wrappedtitle      = computed(() => {
            return useWrappedString(data.value?.title, [...data.value?.focustext] ?? [])
        })

        const _trieOptions          = ref({
            fields: ['terms', 'regions'],
            config: {
                min: 3,
                idFieldOrFunction: 'id',
                ignoreCase: true
            }
        })

        /**
         * Get a TrieSearch instance
         *
         * @param {array} dataset A collection of marker data used for searching
         * @link https://github.com/joshjung/trie-search?tab=readme-ov-file#deep-array-mapping
         */
         const getTrieIntance = (dataset) => {
            const t = new TrieSearch(_trieOptions.value.fields, _trieOptions.value.config)
            if(Array.isArray(dataset)) {
                t.addAll(dataset)
                // The categories & regions are arrays, so deep mapping of these fields is required.
                dataset.map( v => {
                    v?.regions.map( r => {
                        t.map(r, v)
                    })
                    v?.terms.map( c => {
                        t.map(c, v)
                    })
                })
            }
            return t
        }

        /**
         * Watch changes to the term. If this ref changes, react by performing a search.
         * The search uses a "Trie Tree" algorithm to quickly search through a collection of
         * our Partner instances.
         */
        watch(term, (n, o) => {
            if (!!n) {
                // filter null values
                partnerlist.value = null
                let results = []

                /**
                 * Iterate over the term keys and filter the dataset one field at a time.
                 *
                 * The logical progression of the search doesn't have to start with `regions`, instead
                 * this method will reduce the terms beginning with the either field (single value)
                 * to reduce the number of results spread from our dataset ref.
                 */
                const fields = Object.keys(term.value)
                if(!!fields.length) {
                    let filteredset = [...dataset.value]
                    fields.map( f => {
                        const t = getTrieIntance(filteredset)
                        const terms = !!term.value[f] ? term.value[f] : ['all']
                        filteredset = t.search(terms)
                    })

                    results = [...filteredset]
                }

                // Sort alphabetically, extract record (Partner) instance
                results = results.sort((a,b) => a.record.props.title.localeCompare(b.record.props.title))
                results = [...results ?? []].map(item => item.record)

                /**
                 * This is 100% a hack to work around the race-condition created when updating
                 * a reactive variable `partnerlist` immediately after removing all DOM elements
                 * for each Partner instance in the collection.
                 */
                // let __to = null
                // __to = setTimeout(() => {
                //     partnerlist.value = results
                //     label.value = `${partnerlist.value?.length} Matches`
                //     loading.value = false
                //     clearTimeout(__to)
                // }, delay.value)

                partnerlist.value   = results
                label.value         = `${partnerlist.value?.length} Matches`
                loading.value       = false
            }
        })

        const filterhandler = (e) => {
            loading.value = true
            // const el    = [...root.value?.querySelectorAll('rs-griditem:not([filtercontainer])')]
            const frm   = e.target?.closest('form')
            if (frm instanceof HTMLFormElement) {
                // Remove all existing rs-griditem elements,
                // except for the fitlercontainer
                // if (el.length) {
                //     el.map(v => v.remove())
                // }

                // Serialize our form values as an array to see if we should perform a search
                const data = [...(new FormData(frm)).values()]?.filter(v => !!v)
                if (data?.length) {
                    // Serialize our form values and initialize our term ref
                    term.value = Object.fromEntries(new FormData(frm))
                }
            }
        }

        watch(root, (n, o) => {
            if(n instanceof HTMLElement) {
                store.observe(n)
            }
        })

        watch(data, (n, o) => {
            if (n) {
                const {props}       = useNormalizeComponentProps(data)
                layoutprops.value   = {...props.value}
                const r = new RegExp('(^[0-9]+)')
                if (!!layoutprops.value?.label && !(r.test(layoutprops?.value?.label))) {
                    layoutprops.value[layoutprops.value.label] = ''
                    delete layoutprops.value.label
                }

                const { properties } = useNormalizeContentProps(data)
                contentprops.value   = { ...properties.value ?? {} }

                links.value = [...data.value?.links ?? []]

                // Assign components to partners ref
                partnerlist.value = partners.value = data.value.components

                // Intialize our dataset ref
                dataset.value = []

                // Intialize our filterfields and segment them by types
                fitlerfields.value = new Map()
                fitlerfields.value.set('regions', [])
                fitlerfields.value.set('terms', [])
                data.value.components?.map( v => {
                    // Build a map of regions and terms
                    v?.props.taxonomies?.map( j => {
                        const key = j.typeid
                        const val = j.name
                        switch(key) {
                            case 7: // regions
                                fitlerfields.value.set('regions', [...(new Set([...fitlerfields.value.get('regions'), val]))])
                                break;
                            default: // everything else is a term
                                fitlerfields.value.set('terms', [...(new Set([...fitlerfields.value.get('terms'), val]))])
                                break;
                        }
                    })

                    // Generate a Trie Tree
                    const trie = {
                        id:         v.props?.id,
                        record:     v,
                        terms:      [...v?.props.taxonomies?.map(tr => (tr.typeid !== 7) ? tr.name : null).filter(tr => !!tr), 'All'],
                        regions:    [...v?.props.taxonomies?.map(tr => (tr.typeid === 7) ? tr.name : null).filter(tr => !!tr), 'All'],
                    }

                    dataset.value.push(trie)
                }) ?? [];

                // Build our filter field values
                categories.value    = [...(new Set([...fitlerfields.value.get('terms')]))].sort((a,b) => a.localeCompare(b))
                regions.value       = [...(new Set([...fitlerfields.value.get('regions')]))].sort((a,b) => a.localeCompare(b))

                // Initialize the first search by passing an object literal with the default search terms
                term.value = {regions: 'All', terms: 'All'}
            }
        })

        onBeforeMount(() => {
            const params = props.info ? JSON.parse(props?.info) : null
            if (params?.constructor == Object && !!params?.prefetch) {
                useComponentdata({...params, query: 'readPartnerList', data: data})
            }else{
                if(!!props.config) {
                    data.value = (JSON.parse(props.config))?.props
                }
            }
        })

        return {
            root,
            data,
            label,
            regions,
            categories,
            layoutprops,
            contentprops,
            partners,
            partnerlist,
            links,
            showfilters,
            showtitle,
            showcontent,
            showlinks,
            wrappedtitle,
            filterhandler,
            loading
        }
    }
}
</script>

<style lang="scss">
@include foundation();
</style>
