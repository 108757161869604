export default class SearchTerm
{
    constructor(terms) {
        if(!terms || !({...terms}?.constructor === Object)) {
            throw `Terms must be of type Object or instance of SearchTerm, and contain the properties: categories, regions, values. ${typeof terms} supplied.`
        }
        this._categories = terms?.categories
        this._regions    = terms?.regions
        this._values     = terms?.values
    }

    get(target, prop, receiver) {
        return (params) => {
            return target[prop](params);
        }
    }

    set(target, key, value) {
        target[key] = value
        return true
    }

    get categories() {
        return this._categories
    }

    set categories(value) {
        this._categories = value
    }

    get regions() {
        return this._regions
    }

    set regions(value) {
        this._regions = value
    }

    get values() {
        return this._values
    }

    set values(value) {
        this._values = value
    }

    get all() {
        const results = this.reduce([...this._categories ?? [], ...this._regions ?? []])
        return [...new Set(results)]
    }

    get friendly() {
        return `Searching${this._values ? ' for terms, "' + this._values.trim() + '",' : ''} "${this._categories?.length ? this._categories.join(", ") : 'all'}" in categories, and "${this._regions?.length ? this._regions.join(", ") : 'all'}" in regions`
    }

    toString() {
        return this.reduce([...this._values?.split(' ') ?? [], ...this.all ?? []])?.join(', ')
    }

    toObject() {
        return {
            regions: this.reduce([...this._regions ?? []]),
            categories: this.reduce([...this._categories ?? []]),
            values: this._values
        }
    }

    toJSON() {
        return JSON.parse(this.toObject() ?? {
            regions: null,
            categories: null,
            values: null,
        })
    }

    reduce(a) {
        return a?.reduce((acc, i) => i ? [...acc, i] : acc, [])
    }
}