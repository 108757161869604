<template>
    <appbar ref="root" id="Appbar" fixed default :="modeprops" easing>
        <a href="/" branding rel="home" caps weight="400" scale="sm" gutters="0" title="Discover Surrey, BC">
            <span label>Discover Surrey, BC</span>
            <AppLogo alt="Discover Surrey BC" :focused="active" />
        </a>
        <button aria-label="Toggle Search" search noappearance @click="togglemode($event, 'search')">
            <IconSearch search :focused="active" />
        </button>
        <button aria-label="Toggle Navigation" toggle noappearance @click="togglemode($event, 'nav')">
            <IconMenu toggle :focused="active" />
        </button>
        <Suspense>
            <template #default>
                <rs-grid content easing aria-label="Main" :="modeprops">
                    <template v-if="store.appbartype !== 'search'">
                        <AppbarLinks v-if="!!linkgroups?.primary.length" type="primary" primary xxs-1 gap-24 :config="linkgroups?.primary" elastic />
                        <rs-grid locations xxs-1 gap-48 v-if="!!linkgroups?.secondary.length">
                                <AppbarLinks type="secondary" secondary xs-1 gap-24 :config="linkgroups?.secondary" inline elastic group />
                                <UIContent v-for="(item, index) in uicontentlist" :key="`block-${item?.id}`" :config="item.props" uicontent group />
                        </rs-grid>
                    </template>
                    <template v-else>
                        <form action="/" method="GET" novalidate searchform easing rounded radius-sm>
                            <input type="search" name="s" placeholder="Search" noappearance easing required />
                            <button aria-label="Search" noappearance easing><icon easing>search</icon></button>
                        </form>
                        <template v-if="searchcontent">
                            <div instructions v-html="searchcontent" scale="body-xs"></div>
                        </template>
                    </template>
                </rs-grid>
            </template>
            <template #fallback>
                <MDSkeleton>
                    <MDBlocker active="true" cursor="progress" type="default" />
                </MDSkeleton>
            </template>
        </Suspense>
    </appbar>
</template>

<script>
import { ref, computed, onBeforeMount, watch } from 'vue'
import { useStore } from '@/javascript/lib/store'
import { useComponentdata, useNormalizeComponentProps } from '@/javascript/lib/composables'
import AppbarLinks from '@/components/AppbarLinks.ce.vue'
import Address from '@/components/Address.ce.vue'
import IconMenu from '@/components/IconMenu.ce.vue'
import IconSearch from '@/components/IconSearch.ce.vue'
import MDBlocker from '@/components/MDBlocker.vue'
import MDSkeleton from '@/components/MDSkeleton.ce.vue'
import AppLogo from '@/components/AppLogo.ce.vue'
import UIContent from '@/components/UIContent.ce.vue'

export default {
    name: "AppBar",
    components: {AppLogo, AppbarLinks, Address, IconMenu, IconSearch, MDBlocker, MDSkeleton, UIContent},
    props: {
        config: {
            type: String,
            default() {
                return null
            }
        },
        info: {
            type: String,
            default() {
                return null
            }
        },
        heading: {
            type: String,
            default() {
                return null
            }
        },
        active: {
            type: Boolean,
            default() {
                return false;
            }
        }
    },
    setup(props) {
        const root          = ref(null)
        const store         = useStore()
        const data          = ref(null)
        const uicontentlist = ref(null)
        const searchcontent = ref(null)
        const toggle        = ref(false)
        const addresses     = ref(null)
        const layoutprops   = ref(null)
        const linkgroups    = ref(null)
        const active        = ref(false)
        const scrolling     = ref(false)
        const scrollingprops  = ref(null)
        const modeprops     = ref(null)
        const html          = ref(null)
        const observer      = ref(null)

        const togglemode = (e, type) => {
            store.menuactive = active.value = !active.value
            store.appbartype = type ?? 'nav'
            modeprops.value = !!active.value ? {active: ''} : {}

            if (store.appbartype !== 'nav') {
                modeprops.value = {...modeprops.value, scrolling: '', searchactive: ''}
            }

            if (!!scrolling.value) {
                modeprops.value = {...modeprops.value, scrolling: ''}
            }
            html.value?.classList.toggle('md-no-scroll', !!active.value)
        }

        watch(root, (n, o) => {
            html.value = document.querySelector('html')
            const el = [...document.querySelectorAll('rs-uielementalheader, [rs-elemental="uielementalpageheader"]')]?.shift()

            if (el instanceof HTMLElement) {
                const settings   = {
                    threshold: [0.0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1.0]
                }
                observer.value = new IntersectionObserver(entries => {
                    entries.map((entry) => {
                        const b = (entry.intersectionRatio <= 0.2)
                        scrolling.value = b
                        modeprops.value = b ? {scrolling: ''} : {}
                    })
                }, settings)
                observer.value.observe(el)
            } else {
                modeprops.value = {scrolling: ''}
            }
        })

        watch(data, (newvalue, oldvalue) => {
            const {props}       = useNormalizeComponentProps(data)
            layoutprops.value   = {...props.value}
            if (data.value?.layout?.contentprops) {
                contentprops.value = {}
                data.value?.layout?.contentprops?.map( v => {
                    contentprops.value[v.key] = v.value
                });
            }

            // Link Groups
            if(data.value?.linkgroups?.length) {
                linkgroups.value = {}
                data.value.linkgroups.map( v => {
                    const k = v.key.replaceAll(/(^\w+-|-\w+$)/gi, '') // @todo refactor this so the pattern accounts for different combinations
                    linkgroups.value[k] = v.links
                })
            }

            // UI Content
            uicontentlist.value = data.value?.components?.filter(v => v?.props?.layout?.key !== 'search-instructions')
            searchcontent.value = data.value?.components?.filter(v => v?.props?.layout?.key === 'search-instructions')?.shift()
            if (searchcontent.value) {
                searchcontent.value = searchcontent.value?.props?.content
            }
        })

        onBeforeMount(() => {
            const params = props.info ? JSON.parse(props?.info) : null
            if (params?.constructor == Object && !!params?.prefetch) {
                useComponentdata({...params, query: 'readGlobalComponent', data: data})
            }else{
                if(!!props.config) {
                    data.value = (JSON.parse(props.config))?.props
                }
            }
        })

        return {
            root,
            store,
            modeprops,
            togglemode,
            toggle,
            data,
            linkgroups,
            scrollingprops,
            scrolling,
            active,
            uicontentlist,
            searchcontent
        }
    }
}
</script>

<style lang="scss">
@include foundation();
</style>
