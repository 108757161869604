<template>
    <template v-if="editable">
        <component :is="tag" contenteditable role="textbox" :wrap="wrap" :gutters="gutters" :scale="scale" :display="display" :variant-align="align" :variant="variant"/>
    </template>
    <template v-else>
        <component :is="tag" :wrap="wrap" :gutters="gutters" :scale="scale" :display="display" :variant-align="align" :variant="variant">
            <template v-if="!label"><slot></slot></template>
            <template v-else>{{ label }}</template>
        </component>
    </template>
</template>

<script>
import { computed } from 'vue';


export default {
    name: "MDText",
    props: {
        label: {
            type: [String,Number],
            default() {
                return 'Untitled';
            }
        },
        wrap: {
            type: Boolean,
            default() {
                return true;
            }
        },
        gutters: {
            type: [String,Number],
            default() {
                return 0;
            }
        },
        scale: {
            type: String,
            default() {
                return 'body1'
            }
        },
        tag: {
            type: String,
            default() {
                return 'mdtext'
            }
        },
        diplay: {
            type: String,
            default() {
                return 'inline'
            }
        },
        variant: {
            type: String,
            default() {
                return 'normal';
            }
        },
        align: {
            type: String,
            default() {
                return 'left';
            }
        },
        editable: {
            type: Boolean,
            default() {
                return false;
            }
        }
    },
    setup(props) {
        const scale     = computed( () => props.scale ?? 'body1' )
        const display   = computed( () => props.display ?? 'inline' )
        const editable  = computed( () => !!props.editable )
        return {
            scale,
            display,
            editable
        }
    }
}
</script>

<style lang="scss">
@include foundation();
</style>

