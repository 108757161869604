<template>
    <info>
        <dl scale="body-sm">
            <dt casing="display-xxs" title v-html="data?.title"></dt>
            <dd address>
                <icon class="md-icon">place</icon>
                <a :href="maplink" rel="nofollow" target="_blank" meta-label v-html="data?.formatted_address" />
            </dd>
            <dd phone><icon class="md-icon">phone</icon><a :href="`tel: ${data?.address?.phone}`" meta-label v-html="data?.address?.phone" /></dd>
            <dd url><icon class="md-icon">link</icon><a :href="data?.address?.url" target="_blank" meta-label v-html="`Visit Website`" /></dd>
        </dl>
    </info>
</template>

<script>
import { ref, computed, onBeforeMount } from 'vue';

export default {
    name: "InfoWindow",
    components: {},
    props: {
        config: {
            type: Object,
            default() {
                return null
            }
        }
    },
    setup(props) {
        const data      = ref(null)

        const maplink   = computed(() => {
            let a = data.value?.formatted_address
            let val = null
            if (a) {
                const addr = encodeURI(a)
                val = `https://www.google.com/maps/dir/?api=1&origin=&destination=${addr}&travelmode=driving`
            }

            return val
        })

        onBeforeMount(() => {
            data.value = props.config
        })

        return {
            data,
            maplink
        }
    }
}
</script>
