<template>
    <Suspense>
        <template #default>
            <rs-container ref="root" rs-elemental="uielementaltaxonomylist" :="layoutprops">
                <rs-grid  xxs-1 sm-10 xl-12>
                    <Term v-for="(item, index) in terms" :key="index" v-memo="terms" :config="item"></Term>
                </rs-grid>
            </rs-container>
        </template>
        <template #fallback>
            <MDSkeleton aspect-16-9>
                <MDBlocker active="true" cursor="progress" type="default" />
            </MDSkeleton>
        </template>
    </Suspense>
</template>

<script>
import { ref, computed, watch, onBeforeMount } from 'vue';
import { useStore } from '@/javascript/lib/store'
import { useWrappedString , useComponentdata, useNormalizeComponentProps, useNormalizeContentProps } from '@/javascript/lib/composables'
import MDButton from '@/components/MDButton.ce.vue'
import MDBlocker from '@/components/MDBlocker.vue'
import MDSkeleton from '@/components/MDSkeleton.ce.vue'
import Picture from '@/components/Picture.ce.vue'
import Term from '@/components/Term.vue'

export default {
    name: "UIElementalTaxonomyList",
    components: {MDButton, MDSkeleton, MDBlocker, Picture, Term},
    props: {
        info: {
            type: String,
            default() {
                return null
            }
        },
        config: {
            type: Object,
            default() {
                return null
            }
        }
    },
    setup(props) {
        const root              = ref(null)
        const store             = useStore()
        const data              = ref(null)
        const layoutprops       = ref(null)
        const contentprops      = ref(null)
        const terms             = ref(null)
        const template          = ref(null)
        const links             = ref(null)
        const currentproperties = ref(null)
        const showcontent       = computed(() => data.value?.display?.displaycontent)
        const showtitle         = computed(() => data.value?.display?.showtitle)
        const showlinks         = computed(() => data.value?.display?.displaylinks && data.value?.links?.length)
        const wrappedtitle      = computed(() => {
            return useWrappedString(data.value?.title, [...data.value?.focustext] ?? [])
        })

        watch(root, (n, o) => {
            if(n instanceof HTMLElement) {
                store.observe(n)
            }
        })

        watch(data, (n, o) => {
            if (n) {
                const {props}       = useNormalizeComponentProps(data)
                layoutprops.value   = {...props.value}
                const r = new RegExp('(^[0-9]+)')
                if (!!layoutprops.value?.label && !(r.test(layoutprops?.value?.label))) {
                    layoutprops.value[layoutprops.value.label] = ''
                    delete layoutprops.value.label
                }

                if (data.value?.layout?.contentprops) {
                    contentprops.value = {}
                    data.value?.layout?.contentprops?.map( v => {
                        contentprops.value[v.key] = v.value
                    });
                }

                // Assign components to terms ref
                terms.value = data.value.components

                links.value = [...data.value?.links ?? []]
                currentproperties.value = { ...data.value.components?.[0]?.props ?? {}}

                const { properties } = useNormalizeContentProps(currentproperties)
                contentprops.value   = { ...properties.value ?? {} }
                template.value       = contentprops.value.template
            }
        })

        onBeforeMount(() => {
            const params = props.info ? JSON.parse(props?.info) : null
            if (params?.constructor == Object && !!params?.prefetch) {
                useComponentdata({...params, query: 'readMosaic', data: data})
            }else{
                if(!!props.config) {
                    data.value = (JSON.parse(props.config))?.props
                }
            }
        })

        return {
            root,
            data,
            layoutprops,
            contentprops,
            template,
            terms,
            links,
            showtitle,
            showcontent,
            showlinks,
            wrappedtitle
        }
    }
}
</script>

<style lang="scss">
@include foundation();
</style>
