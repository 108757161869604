import { clamp } from '@/javascript/lib/utils'

let _instance       = null
let _n              = 0
let _pause          = false
let _framecount     = 0
let _fpsinterval, _starttime, _now, _then, _elapsed, _p, _tickinterval

const __ = (newtime) => {
    if (_pause) {
        return
    }

    _now     = newtime
    _elapsed = _now - _then

    if (_elapsed > _fpsinterval) {
        _n += _elapsed
        _then = _now - (_elapsed % _fpsinterval)

        const sincestart    = _now - _starttime
        const fps           = Math.round(1000 / (sincestart / ++_framecount) * 100) / 100
        const s             = Math.round(sincestart / 1000 * 100) / 100
        const debug         = `Elapsed time: ${Math.round(s)} secs (${s * 1000}ms) @ ${fps}fps. Progress: ${_p}%`
        const _tickms       = (_tickinterval * 1000)
        const i             = (_n >= _tickms)
        const j             = ((clamp(Math.round(_n), 0, _tickinterval * 1000) % _tickms) === 0)

        const evt = {
            p:      _p = clamp((_n / _tickms) * 100, 0, 100),
            s:      (clamp(Math.round(_n), 0, _tickinterval * 1000)),
            fps:    fps,
            debug:  debug
        }

        if (i) {
            _instance.dispatchEvent(new CustomEvent('onTickInterval', {
                detail: {...evt}
            }))
            _n = 0
        } else {
            _instance.dispatchEvent(new CustomEvent('onTick', {
                detail: {...evt}
            }))
        }
    }

    requestAnimationFrame(__)
}

class tick extends EventTarget {
    constructor(fps, intval) {
        super()
        _fpsinterval  = 1000 / fps
        _tickinterval = intval
    }

    _pause() {
        _pause = true
        _starttime = _then = 0
    }

    start() {
        _starttime = _then = window.performance.now()
        __()
    }
}

export const useTick = (fps, seconds) => {
    if(_instance == null) {
        return _instance = new tick(fps, seconds)
    }
    return _instance
}
