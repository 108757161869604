<template>
    <panel>
        <panelscroll>
            <media v-if="image" :="styleprops">
                <Picture :config="image" :alt="image?.alt ?? data?.title" easing />
            </media>
            <placeinfo>
                <ul listreset>
                    <li scale="body-sm">
                        <template v-if="address">
                            <icon class="md-icon">place</icon> <a :href="maplink" target="_blank" rel="nofollow" meta-label v-html="address" />
                        </template>
                        <template v-else>
                            <icon class="md-icon">place</icon> <span cmeta-label v-html="address" />
                        </template>
                    </li>
                </ul>
            </placeinfo>
            <content v-if="data?.content" v-html="data?.content" scale="body-sm"></content>
        </panelscroll>
    </panel>
</template>

<script>
import { ref, watch, computed, onBeforeMount } from 'vue';
import { useNormalizeContentProps } from '@/javascript/lib/composables'
import Picture from '@/components/Picture.ce.vue'

export default {
    name: "Drawer",
    components: {Picture},
    props: {
        config: {
            type: Object,
            default() {
                return null
            }
        }
    },
    setup(props) {
        const data          = ref(null)
        const contentprops  = ref(null)
        const image         = ref(null)
        const address       = ref(null)
        const styleprops    = ref(null)
        const maplink   = computed(() => {
            let a = data.value?.map?.formatted_address
            let val = null
            if (a) {
                const addr = encodeURI(a)
                val = `https://www.google.com/maps/dir/?api=1&origin=&destination=${addr}&travelmode=driving`
            }

            return val
        })

        watch(data, (newvalue, oldvalue) => {
            if (newvalue) {
                const {properties} = useNormalizeContentProps(data)
                contentprops.value = {...properties.value}
                image.value = !!data.value?.image ? {...data.value?.image} : null

                address.value = data.value?.map?.formatted_address

                const bgcolor = data.value?.display?.backgroundcolor
                styleprops.value = !!bgcolor ? {style : `background-color: ${bgcolor}`} : null
            }
        })

        watch(() => props.config?.props, (newvalue, oldvalue) => {
            data.value = props.config?.props
        })

        onBeforeMount(() => {
            data.value = props.config?.props
        })

        return {
            data,
            image,
            address,
            maplink,
            styleprops
        }
    }
}
</script>
