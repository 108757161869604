<template>
    <Suspense>
        <template #default>
            <rs-container ref="root" rs-elemental="uielementalheading" :="layoutprops">
                <rs-heading v-if="showtitle">
                    <MDText ref="heading" :tag="tag" heading :focustext="focustext" weight="regular" :casing="`display-${contentprops?.scale}`" gutters="0" caps v-html="wrappedtitle" :="contentprops" />
                </rs-heading>
            </rs-container>
        </template>
        <template #fallback>
            <MDSkeleton aspect-16-9>
                <MDBlocker active="true" cursor="progress" type="default" />
            </MDSkeleton>
        </template>
    </Suspense>
</template>


<script>
import { ref, computed, watch, onBeforeMount } from 'vue'
import { useStore } from '@/javascript/lib/store'
import { getAxiosInstance, axioscacheconfig } from '@/javascript/lib/api'
import { useWrappedString, useComponentdata, useNormalizeComponentProps } from '@/javascript/lib/composables'
import MDSkeleton from '@/components/MDSkeleton.ce.vue'
import MDBlocker from '@/components/MDBlocker.vue'
import MDText from '@/components/MDText.vue'

export default {
    name: "UIElementalHeading",
    components: {MDText, MDBlocker, MDSkeleton},
    props: {
        info: {
            type: String,
            default() {
                return null
            }
        },
        config: {
            type: Object,
            default() {
                return null
            }
        }
    },
    setup(props) {
        const store         = useStore()
        const root          = ref(null)
        const heading       = ref(null)
        const __to          = ref(null)

        const api           = getAxiosInstance()
        const data          = ref(null)
        const layoutprops   = ref({})
        const contentprops  = ref(null)
        const wrappedtitle  = computed(() => {
            return useWrappedString(data.value?.title, [...data.value?.focustext] ?? [])
        })
        const showtitle     = computed(() => !!data.value?.display?.showtitle && !!data.value?.title)
        const focustext     = computed(() => ([...data.value?.focustext] ?? [])?.length ? true : null)
        const tag           = computed(() => {
            switch (contentprops.value?.scale) {
                case 'xl':
                case 'lg':
                case 'mm':
                    return 'h1'
                case 'sm':
                    return 'h2'
                case 'xs':
                    return 'h3'
                case 'xxs':
                    return 'h4'
                default:
                    return 'h2'
            }
        })

        watch(heading, (newvalue, oldvalue) => {
            if(newvalue instanceof HTMLElement) {
                store.observe(newvalue, () => {
                    __to.value = setTimeout(() => {
                        heading.value?.setAttribute('easing', '')
                        clearTimeout(__to.value)
                    }, 2500);
                })
            }
        })

        watch(data, (newvalue, oldvalue) => {
            const {props}       = useNormalizeComponentProps(data)
            layoutprops.value   = {...props.value}
            contentprops.value  = contentprops.value ?? {}

            data.value?.layout?.contentprops?.map( v => {
                const p = (v.key == 'scale') ? 'casing' : v.key
                contentprops.value[p] = `display-${v.value}`
            })
        })

        onBeforeMount(() => {
            const params = props.info ? JSON.parse(props?.info) : null

            if (params?.constructor == Object && !!params?.prefetch) {
                useComponentdata({...params, query: 'readBlogTitle', data: data})
            }else{
                if(!!props.config) {
                    data.value = (JSON.parse(props.config))?.props
                }
            }
        })


        return {
            root,
            heading,
            data,
            showtitle,
            focustext,
            wrappedtitle,
            layoutprops,
            contentprops,
            tag
        }
    }
}
</script>

<style lang="scss">
@include foundation();
</style>
