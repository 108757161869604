/**
 * @class MapSymbol
 * @description A simple class to flatten deeply nested properties of a MapSymbol schema (from graphql),
 *              Allows authors to reference properties related to rendering the symbol as an SVGUseElement
 *              Helper methods include property objects for use as attribute maps in Vue
 */
export default class MapSymbol {
    constructor(props) {
        this._uuid          = props?.map?.uuid
        this._id            = parseInt(props?.map?.id, 10)
        this._title         = props?.title
        this._type          = props?.map?.type
        this._alias         = props?.map?.alias
        this._title_debug   = null
        this._content       = props?.content
        this._description   = props?.map?.description
        this._interactive   = !!props?.map?.interactive
        this._links         = [...props?.links ?? []]

        this._x             = props?.map?.geometry?.location?.lat
        this._y             = props?.map?.geometry?.location?.lng

        const {x, y, w, h} =  props?.map?.geometry?.viewbox
        this._vbx           = x
        this._vby           = y
        this._vbw           = w
        this._vbh           = h

        this._url           = null
        this._raw           = props?.map?.geometry?.data
    }

    get content() {
        return this._content
    }

    get x() {
        return this._x
    }

    get y() {
        return this._y
    }

    get title() {
        return this._title
    }

    get type() {
        return this._type
    }

    get debugTitle() {
        return this._title_debug = this._title_debug ?? `ID: ${this._id} - ${this._alias} - (x: ${this._x}, y: ${this._y})`
    }

    get uuid() {
        return this._uuid
    }

    get alias() {
        return this._alias
    }

    get links() {
        return this._links
    }

    get url() {
        return this._url
    }

    set url(val) {
        this._url = val
    }

    get interactive() {
        return this._interactive ? {interactive: ''} : {}
    }

    get symbolID()
    {
        return {
            id: `${this._alias}`
        }
    }

    get props() {
        const p = {...this.interactive}

        p.x = this._x
        p.y = this._y
        p.href = `#${this._alias}`
        p.uuid = this._uuid

        return p
    }

    get symbolProps() {
        return {
            id: this._alias,
            viewBox: `${this._vbx} ${this._vby} ${this._vbw} ${this._vbh}`,
            width: this._vbw,
            height: this._vbh
        }
    }

    get svg() {
        let svg = document.createElementNS("http://www.w3.org/2000/svg", "svg")
        svg.innerHTML = this._raw
        let symbol = svg.querySelector('symbol')
        return symbol?.innerHTML
    }
}
