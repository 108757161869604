<template>
    <svg id="icon-menu" :close="close" v-bind="$attrs" :="iconprops" viewBox="0 0 48 48">
        <line x1="0" y1="24" x2="48" y2="24" :stroke="clr" stroke-width="6px" />
        <line x1="0" y1="24" x2="48" y2="24" :stroke="clr" stroke-width="6px" />
        <line x1="0" y1="24" x2="48" y2="24" :stroke="clr" stroke-width="6px" />
        <rect width="48" height="48" fill="none" />
    </svg>
</template>

<script>
import { ref, computed, watch } from 'vue'
import { useStore } from '@/javascript/lib/store'

export default {
    name: "IconMenu",
    props: {
        config: {
            type: Object,
            default() {
                return null
            }
        }
    },
    setup(props) {
        const data  = ref(null)
        const clr   = ref('#e68346')
        const store = useStore()
        const iconprops = ref(null)

        watch(() => store.menuactive, (n, o) => {
            iconprops.value = (store?.menuactive) ? {close: ''} : null
        })

        return {
            data,
            clr,
            iconprops
        }
    }
}
</script>
