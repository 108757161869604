<template>
    <toast :active="active()" ref="toast">
        <toastheading>
            <MDText v-if="!!label" :label="label" scale="subtitle-lg" branded/>
            <MDText v-if="!!message" :label="message" scale="body-sm"/>
        </toastheading>
        <markerlist v-if="!!activemarkers()?.length">
            <markers>
                <Marker v-for="(item, index) in activemarkers()" @click="select" :key="`marker-${item?.feature.getProperty('id')}`" :markerid="item?.feature.getProperty('id')" :config="item"></Marker>
            </markers>
        </markerlist>
    </toast>
</template>

<script>
import { ref, computed } from 'vue'
import MDText from '@/components/MDText.vue';
import Marker from '@/components/Marker.vue';
import { useStore } from '@/javascript/lib/store'

export default {
    name: "MDToast",
    components: {MDText, Marker},
    props: {
        label: {
            type: String,
            default() {
                return null;
            }
        },
        message: {
            type: String,
            default() {
                return null;
            }
        },
        active: {
            type: Boolean,
            default() {
                return null;
            }
        },
        markers: {
            type: Array,
            default() {
                return null;
            }
        },
        timeout: {
            type: Number,
            default() {
                return 5000
            }
        }
    },
    setup( props ) {
        const store = useStore()
        const state = store.mapstate
        const toast = ref(null)
        const active = computed(() => {
            return () => {
                return props.active ?? null
            }
        })

        const activemarkers = computed(() => {
            return () => {
                return state?.activemarkers ?? null
            }
        })

        const select = (e) => {
            const element = e.currentTarget
            if (element instanceof HTMLElement) {
                e.preventDefault()
                const id = element.getAttribute('markerid')
                state.selection = {id: id}
            }
        }

        return {
            active,
            activemarkers,
            toast,
            select
        }
    }
}
</script>
