<template>
    <Suspense>
        <template #default>
            <rs-container ref="root" rs-elemental="uielementaleventshowcase" :="layoutprops">
                <rs-grid  xxs-1 sm-12 span-xs-6 ref="grid" :="gridprops">
                    <rs-griditem v-if="showtitle || showcontent" :="gridprops" content>
                        <MDText tag="overline" overline underlined scale="body-xs" weight="700" caps label="Event"/>
                        <rs-heading v-if="showtitle">
                            <h2 v-if="link?.url" casing="display-sm" gutters="0" caps elastic><a :href="link?.url" :title="eventTitle" v-html="eventTitle"></a></h2>
                            <h2 v-else casing="display-mm" gutters="0" caps elastic v-html="eventTitle"></h2>
                        </rs-heading>
                        <MDText v-if="!!startdate" tag="subtitle" caps scale="body-xs" weight="700" v-html="`Starting <time>${startdate}</time>`"/>
                    </rs-griditem>
                    <rs-griditem :="gridprops" media rounded radius-sm>
                        <Picture v-if="!!image" :config="image" :alt="image?.alt ?? data?.title" easing stackable-item />
                    </rs-griditem>
                </rs-grid>
            </rs-container>
        </template>
        <template #fallback>
            <MDSkeleton aspect-21-9>
                <MDBlocker active="true" cursor="progress" type="default" />
            </MDSkeleton>
        </template>
    </Suspense>
</template>

<script>
import { ref, computed, watch, onBeforeMount } from 'vue'
import { useStore } from '@/javascript/lib/store'
import { getAxiosInstance } from '@/javascript/lib/api'
import { useWrappedString, useComponentdata, useNormalizeLayoutProps, useNormalizeRatioProps, useNormalizeContentProps } from '@/javascript/lib/composables'
import Picture from '@/components/Picture.ce.vue'
import MDSkeleton from '@/components/MDSkeleton.ce.vue'
import MDButton from '@/components/MDButton.ce.vue'
import MDBlocker from '@/components/MDBlocker.vue'
import MDText from '@/components/MDText.vue'

export default {
    name: "UIElementalEventShowcase",
    components: {Picture, MDButton, MDBlocker, MDSkeleton, MDText},
    props: {
        info: {
            type: String,
            default() {
                return null
            }
        },
        config: {
            type: Object,
            default() {
                return null
            }
        }
    },
    setup(props) {
        const store         = useStore()
        const root          = ref(null)
        const heading       = ref(null)
        const __to          = ref(null)
        const api           = getAxiosInstance()
        const data          = ref(null)
        const link          = ref(null)
        const eventTitle    = ref(null)
        const image   = ref(null)
        const startdate     = ref(null)
        const currentevent  = ref(null)
        const layoutprops   = ref({})
        const contentprops  = ref(null)
        const wrappedtitle  = computed(() => {
            return useWrappedString(data.value?.title, [...data.value?.focustext] ?? [])
        })
        const showcontent       = computed(() => !!data.value?.display?.displaycontent && data.value?.content)
        const showlinks         = computed(() => !!data.value?.display?.displaylinks)
        const showtitle         = computed(() => !!data.value?.display?.showtitle && !!data.value?.title)
        const panellayout       = ref(null)
        const panellayoutprops  = ref(null)
        const panelratios       = ref(null)
        const gridprops         = ref(null)

        watch(root, (newvalue, oldvalue) => {
            if(newvalue instanceof HTMLElement) {
                store.observe(newvalue)
            }
        })

        watch(data, (newvalue, oldvalue) => {
            if (newvalue) {
                const {layout} = useNormalizeLayoutProps(data)
                layoutprops.value = {...layout.value}

                const {ratios} = useNormalizeRatioProps(data)
                panelratios.value = {...ratios.value}

                panellayoutprops.value = {}
                if (data.value?.grid?.gap) {
                    panellayoutprops.value[data.value?.grid?.gap] = ''
                }

                // Build an grid attribute list and merge with panelratios
                gridprops.value = gridprops.value ?? {}
                data.value?.grid?.span?.map( v => {
                    gridprops.value[v] = ''
                })

                gridprops.value = {
                    ...gridprops.value ?? {},
                }

                // Capture the ONE event in data.value.components, then turn it into a reactive ref
                currentevent.value = {...data.value.components.shift()?.props ?? {}}

                const {properties}  = useNormalizeContentProps(currentevent)
                contentprops.value  = {...properties.value ?? {}}
                eventTitle.value    = currentevent.value?.title
                startdate.value     = contentprops.value.startdate
                image.value         = {...currentevent.value?.image ?? {}}
                link.value          = [...currentevent.value?.links ?? []]?.shift()
            }
        })

        onBeforeMount(() => {
            const params = props.info ? JSON.parse(props?.info) : null
            if (params?.constructor == Object && !!params?.prefetch) {
                useComponentdata({...params, query: 'readCarouselContent', data: data})
            }else{
                if(!!props.config) {
                    data.value = (JSON.parse(props.config))?.props
                }
            }
        })

        return {
            root,
            heading,
            data,
            showcontent,
            showlinks,
            showtitle,
            wrappedtitle,
            layoutprops,
            link,
            eventTitle,
            image,
            currentevent,
            panellayout,
            panellayoutprops,
            gridprops,
            contentprops,
            startdate
        }
    }
}
</script>

<style lang="scss">
@include foundation();
</style>
