<template>
    <eventitem>
        <h3 scale="body-xs" weight="700" gutters="0" caps>
            <a :href="`${link?.url ?? '#'}`" v-html="data?.title"></a>
        </h3>
        <p scale="body-xs" v-html="data?.content"></p>
        <time scale="body-xs" v-html="eventdates"></time>
    </eventitem>
</template>

<script>
import { ref, watch, onBeforeMount, computed } from 'vue';
import { useNormalizeContentProps } from '@/javascript/lib/composables'

export default {
    name: "Event",
    components: {},
    props: {
        config: {
            type: Object,
            default() {
                return null
            }
        }
    },
    setup(props) {
        const data          = ref(null)
        const contentprops  = ref(null)
        const link          = ref(null)
        const eventdates    = computed(() => `${contentprops?.value?.startdate} - ${contentprops?.value?.enddate}`)

        watch(data, (newvalue, oldvalue) => {
            if (newvalue) {
                const {properties} = useNormalizeContentProps(data)
                contentprops.value = {...properties.value}
                link.value = [...data.value?.links ?? []]?.shift()
            }
        })

        onBeforeMount(() => {
            data.value = props.config?.props
        })

        return {
            data,
            link,
            contentprops,
            eventdates
        }
    }
}
</script>
