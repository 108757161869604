<template>
    <Suspense>
        <template #default>
            <rs-container ref="root" v-if="data && !!slides?.length" rs-elemental="uielementalcarousel" :="layoutprops" stackable carousel>
                <UIElementalGlider :config="config" :slides="slides"></UIElementalGlider>
            </rs-container>
        </template>
        <template #fallback>
            <MDSkeleton aspect-21-9>
                <MDBlocker active="true" cursor="progress" type="default" />
            </MDSkeleton>
        </template>
    </Suspense>
</template>

<script>
import { ref, watch, onBeforeMount } from 'vue'
import { getAxiosInstance, axioscacheconfig } from '@/javascript/lib/api'
import MDSkeleton from '@/components/MDSkeleton.ce.vue'
import MDBlocker from '@/components/MDBlocker.vue'
import UIElementalGlider from '@/components/UIElementalGlider.vue'

export default {
    name: "UIElementalCarousel",
    components: {UIElementalGlider, MDBlocker, MDSkeleton},
    props: {
        info: {
            type: String,
            default() {
                return null
            }
        },
        config: {
            type: Object,
            default() {
                return null
            }
        }
    },
    setup(props) {
        const root          = ref(null)
        const api           = getAxiosInstance()
        const data          = ref(null)
        const layoutprops   = ref({})
        const config        = ref(null)
        const slides        = ref(null)

        watch(data, (newvalue, oldvalue) => {
            if (newvalue) {
                layoutprops.value   = {...newvalue?.ratios, ...newvalue?.layout}
                slides.value        = newvalue?.slides
                config.value        = newvalue?.glider?.options
            }
        })

        onBeforeMount(() => {
            const info = props.info ? JSON.parse(props?.info) : null
            if (info?.constructor == Object && !!info?.prefetch) {
                api.get(`${info.endpoint}`, axioscacheconfig).then(response => {
                    data.value = response?.data?.props
                }).catch(error => {
                    console.log(error, error.response)
                })
            }else{
                if(!!props.config) {
                    data.value          = (JSON.parse(props.config))?.props
                }
            }
        })

        return {
            root,
            data,
            layoutprops,
            config,
            slides
        }
    }
}
</script>

<style lang="scss">
@include foundation();
</style>
