<template>
    <spinner>
        <dot class="uispinner-dot"/>
        <dot class="uispinner-dot"/>
        <dot class="uispinner-dot"/>
        <dot class="uispinner-dot"/>
    </spinner>
</template>

<script>
export default {
    name: "MDSpinner"
}
</script>
