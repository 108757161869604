<template>
    <slide span-xs-1 span-sm-4 :title="data?.title" :id="`${data?.id}`" :style="slidestyle" alignment="bottom left" aligncontent="left" stackable>
        <Picture :config="data?.image" :alt="data?.image?.alt ?? data?.title" easing stackable-item />
        <slidecontent stackable-item>
            <slidecontent-inner>
                <h3 casing="display-xs" gutters="0" easing caps v-html="data?.title"></h3>
                <p scale="body-sm" gutters="0" v-html="data?.content"></p>
                <MDButton v-for="(item, index) in data?.links" tag="a" :href="item?.url" :target="item?.openinnewwindow ? '_blank' : null" :key="item?.key" :index="index" label="Learn More" :type="index == 0 ? 'contained' : 'outlined'"  :class="item?.css" contained solid />
            </slidecontent-inner>
        </slidecontent>
    </slide>
</template>

<script>
import { ref, computed, watch, onBeforeMount } from 'vue';
import Picture from '@/components/Picture.ce.vue'
import MDButton from '@/components/MDButton.ce.vue'
import { useNormalizeLayoutProps, useNormalizeGridProps, useNormalizeRatioProps, useNormalizeContentProps } from '@/javascript/lib/composables'

export default {
    name: "RepeaterSlide",
    components: {Picture, MDButton},
    props: {
        pos: {
            type: String,
            default() {
                return null
            }
        },
        config: {
            type: Object,
            default() {
                return null
            }
        }
    },
    setup(props) {
        const data          = ref(null)
        const image         = ref(null)
        const layoutprops   = ref(null)
        const contentprops  = ref(null)
        const link          = ref(null)
        const showcontent   = computed(() => data.value?.display?.displaycontent)
        const showtitle     = computed(() => data.value?.display?.showtitle)
        const showlinks     = computed(() => data.value?.display?.displaylinks)

        const slidestyle = ref({
            backgroundColor: null
        })

        watch(data, (newvalue, oldvalue) => {
            if (newvalue) {
                const {props}       = useNormalizeLayoutProps(data)
                const {grid}        = useNormalizeGridProps(data)
                const {ratios}      = useNormalizeRatioProps(data)
                const {properties}  = useNormalizeContentProps(data)

                contentprops.value = {...properties?.value ?? {}}
                layoutprops.value   = {...props?.value ?? {}, ...grid?.value ?? {}, ...ratios?.value ?? {}}

                image.value         = {...data.value?.image}
                link.value = [...data.value?.links ?? []]?.shift()
            }
        })

        onBeforeMount(() => {
            if(props.config?.constructor !== Object) {
                data.value = JSON.parse(props.config)?.props
            }else{
                data.value = props.config?.props
            }
        })

        return {
            data,
            layoutprops,
            contentprops,
            showtitle,
            showcontent,
            showlinks,
            image,
            slidestyle,
            link
        }
    }
}
</script>
