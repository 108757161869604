<template>
    <Suspense>
        <template #default>
            <rs-container ref="root" rs-elemental="uielementalexperience" :="layoutprops">
                <rs-grid  xxs-1 sm-12 span-xs-12 center ref="grid" :="gridprops" stackable>

                    <rs-griditem center v-if="showtitle || showcontent || showlinks" content stackable-item>
                        <rs-content v-if="showcontent" :="contentprops" v-html="data.content" scale="subtitle-mm">
                        </rs-content>
                        <rs-heading v-if="showtitle" :="linkprop" @click.prevent.stop="clickhandler">
                            <h2 weight="regular" casing="display-mm" gutters="0" caps easing v-html="wrappedtitle"></h2>
                            <h3 v-if="data?.subtitle" role="doc-subtitle" scale="subtitle-mm" weight="900" gutters="0" caps easing v-html="data?.subtitle"></h3>
                            <a v-if="link?.url" :href="link?.url" :target="item?.openinnewwindow ? '_blank' : null" :title="link?.title" v-text="`Learn More`" />
                        </rs-heading>
                        <rs-actions v-if="showlinks">
                            <MDButton v-for="(item, index) in data?.links" key: tag="a" :href="item?.url" :target="item?.openinnewwindow ? '_blank' : null" :key="item?.key" :index="index" :label="item?.title" :type="index == 0 ? 'contained' : 'outlined'" :class="item?.css" chunky short white/>
                        </rs-actions>
                    </rs-griditem>

                    <rs-griditem span-mm-4 v-if="assets?.assets?.length" media :="ratioprops" stackable-item>
                        <FourMask stackable-item :config="assets" >
                        </FourMask>
                    </rs-griditem>
                </rs-grid>
            </rs-container>
        </template>
        <template #fallback>
            <MDSkeleton aspect-21-9>
                <MDBlocker active="true" cursor="progress" type="default" />
            </MDSkeleton>
        </template>
    </Suspense>
</template>

<script>
import { ref, computed, watch, onBeforeMount } from 'vue';
import { useWrappedString , useComponentdata, useNormalizeLayoutProps, useNormalizeGridProps, useNormalizeRatioProps } from '@/javascript/lib/composables'
import MDButton from '@/components/MDButton.ce.vue'
import MDBlocker from '@/components/MDBlocker.vue'
import MDSkeleton from '@/components/MDSkeleton.ce.vue'
import FourMask from '@/components/FourMask.ce.vue'
import { uuid } from '@/javascript/lib/utils.js'
import { useStore } from '@/javascript/lib/store'

export default {
    name: "uielementalexperience",
    components: {MDButton, MDSkeleton, MDBlocker, FourMask},
    props: {
        info: {
            type: String,
            default() {
                return null
            }
        },
        config: {
            type: Object,
            default() {
                return null
            }
        }
    },
    setup(props) {
        const root          = ref(null)
        const store         = useStore()
        const data          = ref(null)
        const layoutprops   = ref(null)
        const ratioprops    = ref(null)
        const contentprops  = ref(null)
        const gridprops     = ref(null)
        const grid          = ref(null)
        const image         = ref(null)
        const link          = ref(null)
        const assets        = ref({})
        const showcontent   = computed(() => data.value?.display?.displaycontent)
        const showtitle     = computed(() => data.value?.display?.showtitle)
        const showlinks     = computed(() => data.value?.display?.displaylinks && data.value?.links?.length)
        const clipPathId = `clip-path-${uuid()}`
        const wrappedtitle  = computed(() => {
            return useWrappedString(data.value?.title, [...data.value?.focustext] ?? [])
        })
        const linkprop = computed(() => !!link.value?.url ? {linked: '', title: link.value?.title} : null )

        const clickhandler = (e) => {
            if (!!link.value?.url) {
                switch (link.value?.openinnewwindow) {
                    case true:
                        window.open(link.value?.url, '_blank')
                        break;
                    case false:
                    default:
                        window.location.href = link.value?.url
                        break;
                }
            }
        }

        watch(root, (newvalue, oldvalue) => {
            if(newvalue instanceof HTMLElement) {
                store.observe(newvalue)
            }
        })

        watch(data, (newvalue, oldvalue) => {
            const {layout}      = useNormalizeLayoutProps(data)
            const {ratios}      = useNormalizeRatioProps(data)
            const {grid, gap}   = useNormalizeGridProps(data)
            // Extract the ratios as a value object, but DON'T combine with `layoutprops`
            ratioprops.value    = {...ratios.value}
            layoutprops.value   = {...layout.value, ...grid.value}

            gridprops.value = {...grid.value}
            if (!!gap.value) {
                gridprops.value[gap.value] = ''
            }

            assets.value = {assets: [...data.value?.assets]}
            // console.log(assets.value.assets, data.value.assets)

            link.value = {...data.value?.link ?? {}}
        })

        onBeforeMount(() => {
            const params = props.info ? JSON.parse(props?.info) : null
            if (params?.constructor == Object && !!params?.prefetch) {
                useComponentdata({...params, query: 'readExperience', data: data})
            }else{
                if(!!props.config) {
                    data.value = (JSON.parse(props.config))?.props
                }
            }
        })

        return {
            root,
            data,
            grid,
            gridprops,
            layoutprops,
            ratioprops,
            contentprops,
            showtitle,
            showcontent,
            showlinks,
            image,
            clipPathId,
            assets,
            wrappedtitle,
            clickhandler,
            linkprop
        }
    }
}
</script>

<style lang="scss">
@include foundation();
</style>
