<template>
    <Suspense>
        <template #default>
            <rs-container ref="root" rs-elemental="uielementalpartnermap" :="layoutprops">
                <md-sheet :id="id" :title="title" class="md-sheet" @keyup.esc="esc" :="gridprops">
                    <form mapfilter ref="mapfilter" :disabled="activedetail()">
                        <!-- <field search inset>
                            <fieldcontent filters>
                                <input @input="input" @reset="input" id="search" ref="search" type="search" placeholder="Search">
                                <MDButton @click="inputreset" ref="resetbutton" tag="button" type="icon-only" label="Reset" icon="close" />
                            </fieldcontent>
                        </field> -->
                        <template v-if="showregions && !!regions?.length">
                            <field regions inset>
                                <select name="region" @change.prevent.stop="select($event, 'region')">
                                    <option value="" v-text="`All Regions`" />
                                    <option v-for="(item, index) in regions" v-memo :value="item" :title="item" v-html="item" />
                                </select>
                            </field>
                        </template>
                        <field categories v-if="!!categories?.length">
                            <categorylist>
                                <Category v-for="(item, index) in categories" v-memo animations :selected="selected(`${item.name}`, 'category')" :index="index" :title="`${item.name} (${item.count ?? 0})`" :value="item.name" :count="item.count ?? 0" v-html="item.html.outerHTML"  @click="select($event, 'category')" />
                            </categorylist>
                        </field>
                    </form>
                    <drawer :active="activedetail()" v-html="detail?.outerHTML" ref="drawer" />
                    <map ref="mapcontainer" :key="key"/>
                </md-sheet>
                <MDBlocker v-if="!!state.loading" opacity="transparent-white" mode="absolute" active="true" cursor="progress" type="default" />
                <MDToast :label="toast.label" :message="`${mode == 'default' ? toast.message : ''}`" :active="toast.active" />
                <MDGalleryDialog :label="dialog?.label" :content="dialog?.content" @close="dialogClose" />
            </rs-container>
        </template>
    </Suspense>
</template>

<script>
import { computed, ref, reactive, onBeforeMount, onMounted, onBeforeUpdate, onUpdated, createApp, watch, isProxy, toRaw } from 'vue'
import { useWrappedString, useComponentdata, useNormalizeRatioProps, useNormalizeLayoutProps, useNormalizeGridProps, useNormalizeContentProps } from '@/javascript/lib/composables'
import { getAxiosInstance, axioscacheconfig } from '@/javascript/lib/api'
import { useStore } from '@/javascript/lib/store'
import { getQuery } from '@/javascript/lib/queries'
import SearchTerm from '@/javascript/classes/searchterm'
import Detail from '@/components/Detail.vue';
import Category from '@/components/Category.vue';
import MDGalleryDialog from '@/components/MDGalleryDialog.vue'
import MDText from '@/components/MDText.vue'
import MDButton from '@/components/MDButton.ce.vue'
import MDBlocker from '@/components/MDBlocker.vue'
import MDToast from '@/components/MDToast.vue'
import InfoWindowAdvanced from '@/components/InfoWindowAdvanced.vue'
import TrieSearch from 'trie-search'
import IconDefault from '@/assets/marker.png?url'

export default {
    name: "UIElementalPartnerMap",
    components: {MDText, MDBlocker, MDButton, MDToast, MDGalleryDialog, InfoWindowAdvanced},
    props: {
        id: {
            type: String,
            default() {
                return null
            }
        },
        title: {
            type: String,
            default() {
                return 'Untitled'
            }
        },
        key: {
            type: String,
            default() {
                return 'AIzaSyDD_Ls6Ld_NCnkiBzj6b4XysPoYex1ioG0'
            }
        },
        graphql: {
            type: String,
            default() {
                return '/graphql'
            }
        },
        center: {
            type: Object,
            default() {
                return {
                    lat: 49.1535272,
                    lng: -122.8532013
                }
            }
        },
        info: {
            type: String,
            default() {
                return null
            }
        },
        config: {
            type: Object,
            default() {
                return null
            }
        }
    },

    setup(props, {emit}) {
        const store         = useStore()
        const state         = store.mapstate
        const root          = ref(null)
        const layoutprops   = ref(null)
        const gridprops     = ref(null)
        const data          = ref(null)
        const key           = ref(null)
        const api           = getAxiosInstance()
        const mode          = ref(null)
        const term          = ref(null)

        const showcontent       = computed(() => !!data.value?.display?.displaycontent && !!data.value?.content)
        const showlinks         = computed(() => !!data.value?.display?.displaylinks && (!!data.value?.links?.length || !!addlpartners.value?.length))
        const showtitle         = computed(() => !!data.value?.display?.showtitle && !!data.value?.title)
        const wrappedtitle      = computed(() => {
                return useWrappedString(data.value?.title, [...data.value?.focustext] ?? [])
            })

        const endpoint              = computed(() => (['local', 'localhost'].includes(store.mode)) ? 'https://dev.discoversurreybc.com/graphql' : '/graphql')
        const anchor                = ref(null)
        // const loading               = ref(false)
        const bounds                = ref(null)
        const mapcontainer          = ref(null)
        const mapfilter             = ref(null)
        const search                = ref(null)
        const terms                 = reactive(new SearchTerm({
            categories: null,
            regions: null,
            values: null
        }))
        const toast = ref({
            label: null,
            message: null
        })
        const defaultcategories     = ref(null)
        const defaultregions        = ref(null)
        const categories            = ref(null)
        const regions               = ref(null)
        const showregions           = ref(true)
        const categorySelections    = ref(new Set())
        const regionSelections      = ref(new Set())
        const _trieOptions          = ref({
            fields: ['categories', 'regions', 'title', 'slug'],
            config: {
                min: 3,
                idFieldOrFunction: 'id',
                ignoreCase: true
            }
        })
        const options = {
            zoom: 7,
            streetViewControl: false,
            mapTypeControl: false,
            fullscreenControl: false,
            styles: [...store.mapstyles]
        }
        const detail = ref(null)
        const activedetail = computed(() => {
            return () => {
                return detail.value != null ? true : null
            }
        })
        const drawer = ref(null)
        const dialog = ref({
            label: null,
            content: null
        })
        const dialogClose = (e) => {
            dialog.value.label = null
            dialog.value.content = null
        }
        const esc = (e) => {
            dialogClose(e)
        }
        let map = null

        const selected = computed(() => {
            return (value, type) => {
                switch(type) {
                    case 'region' :
                        return terms.regions?.includes(value) ? '' : null
                    case 'category' :
                        return terms.categories?.includes(value) ? '' : null
                    default:
                        return null
                }
            }
        })

        // Component methods
        const init = () => {
            // Update google map options
            options.center      = new google.maps.LatLng(props.center.lat, props.center.lng)
            options.MapTypeId   = google.maps.MapTypeId.ROADMAP
            options.zoomControlOptions = {
                position: google.maps.ControlPosition.TOP_RIGHT
            }
            map = new google.maps.Map(mapcontainer.value, options)
            mapcontainer.value.addEventListener('click', (e) => {
                const element   = e.target
                const selector  = '[mapinfowindow], .mapinfowindow'
                const content   = e.target.closest(selector)

                if(content && (element instanceof HTMLAnchorElement)) {
                    if (element.matches('.media-wrapper, .address--name, [more]')) {
                        e.preventDefault()
                        const id = parseInt(content.getAttribute('markerid'), 10)
                        state.selection = {id: id}
                    }
                }
            })

            mapfilter.value.addEventListener('submit', (e) => {
                e.preventDefault()
                e.stopImmediatePropagation()
            })

            drawer.value.addEventListener('click', (e) => {
                const element               = e.target
                const selectorClose         = '.view-close'
                const seelctorGalleryItem   = '.view-gallery--item'
                if(element && (element.matches(selectorClose))) {
                    e.preventDefault()
                    detail.value = null
                    closeInfoWindows()
                }
                if(element && (element.matches(seelctorGalleryItem))) {
                    e.preventDefault()
                    const href = element.href
                    displayImage(href)
                }
            })

            // Load data
            state.loading   = true

            const t       = {type: (mode.value == 'spice-trail') ? 'Cuisine' : 'Category'}
            const p       = (mode.value == 'spice-trail') ? {terms: ['spice-trail']} : null
            const queries = [getQuery('readFeatures')(p), getQuery('readRegions')(), getQuery('readTaxonomy')(t)]
            const requests = queries.map((q) => {
                return !!endpoint.value ? api.post(endpoint.value, {...q}, axioscacheconfig) : null
            }).filter((v) => v !== null)

            let __to = null
            api.all(requests).then(responses => {
                responses.forEach(r => {
                    const resp = {
                        server: r.headers.server,
                        status: r.status,
                        value: r.data?.data ?? null
                    }

                    if('readFeatures' in (resp.value ?? {})) {
                        process(resp.value.readFeatures)
                    }
                    if('readTaxonomy' in (resp.value ?? {})) {
                        processCategories(resp.value.readTaxonomy)
                    }
                    if('readRegions' in (resp.value ?? {})) {
                        processRegions(resp.value.readRegions)
                    }
                })

                // Handle anchors in the URL
                if (!!anchor.value || !!term.value) {
                    terms.categories    = [term.value?.name, anchor.value].filter(v => !!v)
                    terms.regions       = [...(defaultregions.value?.size ? defaultregions.value?.values() : regions.value ?? [])]
                    state.search        = {value: terms}
                } else {
                    // Set the default region and terms
                    switch (mode.value) {
                        case 'spice-trail' :
                            terms.categories = data.value?.display?.override ? [...defaultcategories.value?.values() ?? []] : []
                            break;
                        default :
                            terms.categories = [...defaultcategories.value?.values() ?? []]
                            break;
                    }

                    terms.regions       = [...(defaultregions.value?.size ? defaultregions.value?.values() : regions.value ?? [])]
                    terms.values        = null

                    state.search = {value: terms}
                }

            }).catch(err => {
                console.log(err)
            })
        }

        /**
         * Get a TrieSearch instance
         *
         * @param {array} dataset A collection of marker data used for searching
         * @link https://github.com/joshjung/trie-search?tab=readme-ov-file#deep-array-mapping
         */
        const getTrieIntance = (dataset) => {
            const t = new TrieSearch(_trieOptions.value.fields, _trieOptions.value.config)
            if(Array.isArray(dataset)) {
                t.addAll(dataset)

                // The categories & regions are arrays, so deep mapping of these fields is required.
                dataset.map( v => {
                    v?.categories.map( c => {
                        t.map(c, v)
                    })
                    v?.regions.map( r => {
                        t.map(r, v)
                    })
                })
            }
            return t
        }

        /**
         * Get an icon
         */
        const getIconByRegion = () => {
            const uri = new URL(IconDefault, import.meta.url)
            return uri?.toString()
        }

        /**
         * Parse map data into a useable format suiteable for google maps
         *
         * @param {array} data An array of features to be parsed into a Google Maps Data Feature instance
         */
        const process = (data) => {
            if (data) {
                const b = new google.maps.LatLngBounds()
                const markers = new Map()
                const dataset = []
                reset()
                data.map((v) => {
                    const props = {...v}
                    const coords = {
                        lng: parseFloat(props.geometry?.coordinates?.latitude),
                        lat: parseFloat(props.geometry?.coordinates?.longitude)
                    }

                    const marker = new google.maps.Marker({
                        position: new google.maps.LatLng(coords.lng, coords.lat),
                        map: map,
                        title: props.title,
                        icon: {
                            url:        getIconByRegion(),
                            size:       new google.maps.Size(32, 32)
                        },
                        optimized: true,
                        feature: new google.maps.Data.Feature({
                            geometry: coords,
                            properties: props
                        })
                    })

                    const markeritem    = !!props.markeritem ? toHTMLElement(props.markeritem, 'marker') : null
                    props.markeritem    = markeritem
                    props.markerdata    = toMarkerData(props)

                    // Make sure certain collections are in sync
                    if (('regions' in props) && !props?.regions.length) {
                        props.regions = [...v.trie?.regions]
                    }

                    // Click handler for markers
                    google.maps.event.addListener(marker, "click", () => {
                        state.markerclick = {marker: marker}
                    })

                    // Click handler for infowindow close button
                    google.maps.event.addListener(marker, "closeclick", () => {
                        closeInfoWindows()
                    })

                    b.extend(marker.position)

                    // Update the coordinates
                    const o = {
                        ...v,
                        geometry: {
                            coordinates: coords
                        }
                    }

                    markers.set(marker, o)

                    // Generate a Trie Tree
                    const trie = {
                        ...marker.feature.getProperty('trie'),
                        slug:       marker.feature.getProperty('urlsegment') ?? null,
                        id:         marker.feature.getProperty('id'),
                        marker:     marker,
                        categories: [...props.trie.categories ?? []],
                        regions:    [...props.trie.regions ?? []],
                    }

                    dataset.push(trie)
                })

                // Re-center and fit to bounds
                map.setCenter(b.getCenter())
                map.fitBounds(b)

                bounds.value    = b
                state.data      = data
                state.markers   = markers
                state.dataset   = dataset
            }
        }

        /**
         * Parse category data for use in the UI
         *
         * @param {array} data An collection of category information
         */
        const processCategories = (data) => {
            let cats = []

            if(!!data.length) {
                data.map( (v) => {
                    const cat = {
                        ...v,
                        html: getCategoryItem(v)
                    }
                    cats  = [...cats, cat]
                })
                cats.sort((a,b) => a.name.localeCompare(b.name))
            }
            categories.value = [...new Set(cats)]
        }

        /**
         * Parse region data for use in the UI
         *
         * @param {array} data An collection of region information
         */
        const processRegions = (data) => {
            let regs = []
            if(!!data.length) {
                data.map((v) => {
                    regs = [...regs, v.name]
                })
            }
            regions.value = [...new Set(regs.sort())]
        }

        /**
         * Convert a string to HTMLElement for use in the right-side listing
         *
         * @param {string} html An HTML string
         * @param {HTMLElement} element An HTMLElement
         */
        const toHTMLElement = (html, element) => {
            if(html) {
                const template = document.createElement('template')
                template.innerHTML = html
                const content = template.content.querySelector(element ?? 'li').cloneNode(true)
                const img = content.querySelector('img')
                img.setAttribute('loading', 'lazy')
                img.src = img.dataset.deferredSrc
                return {
                    src: img.dataset.deferredSrc,
                    element: content
                }
            }
            return null
        }

        const toMarkerData = (props) => {
            return {
                id: props?.id,
                title: props?.title,
                image: {
                    url: props?.asset.mm,
                    title: props?.title
                }
            }
        }

        /**
         * Reset all markers and info windows
         */
        const reset = () => {
            clearMarkers()
            closeInfoWindows()

            // Clear the last selections
            regionSelections.value.clear()
            categorySelections.value.clear()

            // Clear the selections
            state.activemarkers = null
        }

        const resetAnchor = () => {
            // Clear the anchor
            anchor.value = null
            history.pushState("", document.title, window.location.pathname + window.location.search);
            inputreset(new CustomEvent('reset', {
                detail: {}
            }))
        }

        /**
         * Update the map with a list of markers
         *
         * @param {Array|Map} markers An Array or Map instance containing a collection of markers
         */
        const update = (matches) => {
            if(!!matches && (!!matches?.length || !!matches?.size)) {
                if(matches instanceof Map) {
                    matches = [...matches.keys()]
                }
                if(!Array.isArray(matches)) {
                    throw `Invalid Parameter. Provided terms must be an array. ${typeof matches} supplied.`
                }
                const b = new google.maps.LatLngBounds()
                matches.map(m => {
                    const marker = isProxy(m) ? toRaw(m) : marker
                    marker.setMap(map)
                    b.extend(marker.position)
                })

                // Re-center and fit to bounds
                map.setCenter(b.getCenter())
                map.fitBounds(b)

                state.bounds = b
            }
        }

        /**
         * Update the toast component
         *
         * @param {object} message An object literal with a "label" and "message" property
         */
        const updateToast = (message) => {
            toast.value.label   = message?.label ?? null
            toast.value.message = message?.message ?? null
            toast.value.active  = message?.active ?? null
            toast.value.markers = message?.markers ?? null

            // Store active markers
            state.activemarkers = message?.markers ?? null
        }

        /**
         * Get a marker instance by id
         *
         * @param {number} id The ID of a given marker
         */
        const getMarkerByID = (id) => {
            const markers = state.markers
            if (markers) {
                let m = null
                markers.forEach((value, key, map) => {
                    if (value.id == id) {
                        m = key
                    }
                })
                return m
            }
            return null
        }

        /**
         * Get the source data from a marker instance
         *
         * @param {google.maps.Marker} marker Marker
         */
        const getMarkerDataByMarker = (marker) => {
            const markers = state.markers
            if (markers.has(marker)) {
                return markers.get(marker)
            }
            return null
        }

        /**
         * Show the info window for a given marker
         *
         * @param {google.maps.Marker} marker A Google Maps Marker instance
         */
        const show = (marker) => {
            closeInfoWindows()
            const m = isProxy(marker) ? toRaw(marker) : marker

            if(m?.marker instanceof google.maps.Marker) {
                const w = new google.maps.InfoWindow({
                    content: getInfoWindow(m?.marker),
                    maxWidth: 300
                })
                state.infoWindow = w
                w.open(map, m?.marker)
            }
        }

        /**
         * Display marker-related data
         *
         * @param {object} data An object literal containing the id, marker and marker data
         */
        const display = (mapdata) => {
            if(mapdata?.html) {
                detail.value = mapdata.html
                state.loading = false
                show({marker: mapdata?.marker})
            }
        }

        /**
         * Display an image from the partner gallery.
         *
         * @param {string} href A image URI
         */
        const displayImage = (href) => {
            if (href) {
                const img = document.createElement('img')
                img.src = href
                dialog.value.label = 'Gallery'
                dialog.value.content = img
            }
        }

        /**
         * Get an HTMLElement instance for use in an infoWindow
         *
         * @param {google.maps.Marker} marker Marker instnace
         */
        const getInfoWindow = (marker) => {
            if (marker instanceof google.maps.Marker) {
                const data      = getMarkerData(marker)
                const template  = document.createElement('template')
                let instance    = createApp(InfoWindowAdvanced, {config: data}).mount(document.createElement('template'))
                template.innerHTML = instance.$el.cloneNode(true).outerHTML;
                instance = null
                const content = template.content.querySelector('.mapinfowindow')
                content.setAttribute('markerid', marker.feature.getProperty('id'))
                const img = [...content.querySelectorAll('img')]
                img.map((i) => {
                    i.setAttribute('loading', 'lazy')
                    i.src = i.dataset.deferredSrc
                })
                return content
            }
            return null
        }

        const getFeatureCard = (data) => {
            if (data instanceof Object) {
                const template  = document.createElement('template')
                let instance    = createApp(Detail, {config: data}).mount(document.createElement('template'))
                template.innerHTML = instance.$el?.outerHTML;
                instance = null
                const img = template.content.querySelector('img')
                if (!!data?.image?.url && !!img) {
                    img.setAttribute('srcset', data?.image?.url)
                    img.setAttribute('src', data?.image?.thumbnail)
                }
                const content = template.content.querySelector('.detailcard')
                content.setAttribute('markerid', data?.id)
                return content
            }
            return null
        }

        const getCategoryItem = (data) => {
            if (data instanceof Object) {
                const template  = document.createElement('template')
                let instance    = createApp(Category, {config: data}).mount(document.createElement('template'))
                template.innerHTML = instance.$el.cloneNode(true).outerHTML;
                instance = null
                const content = template.content.querySelector('.listcard')
                return content
            }
            return null
        }

        /**
         * Thin utility wrapper around google.maps.Marker.forEachProperty method
         *
         * @param {google.maps.Marker|null} marker Returns a google maps marker instance
         */
        const getMarkerData = (marker) => {
            const mkr = isProxy(marker) ? toRaw(marker) : marker
            if (mkr instanceof google.maps.Marker) {
                const data = {}
                mkr.feature.forEachProperty( (v, k) => {
                    data[k] = v
                })
                return data
            }
            return null
        }

        /**
         * Clear all markers from the map
         */
        const clearMarkers = () => {
            if(!!state.markers?.size && !!map && (map instanceof google.maps.Map)) {
                state.markers.forEach((data, marker, m) => {
                    const mkr = isProxy(marker) ? toRaw(marker) : marker
                    mkr.setMap(null)
                })
            }
        }

        /**
         * Close all info Windows
         */
        const closeInfoWindows = () => {
            state?.infoWindow?.close()
            state.infoWindow = null
        }

        /**
         * Search field input event handler
         *
         * @param {Event} e Input event
         */
        const input = (e) => {
            const v = e.target.value
            terms.values = v ?? null
            state.search = {value: terms}
        }

        /**
         * Reset the search field
         *
         * @param {PointerEvent} e PointerEvent
         */
        const inputreset = (e) => {
            mapfilter.value.reset()
            terms.categories    = null
            terms.regions       = null
            terms.values        = null

            // Clear the last selections
            regionSelections.value.clear()
            categorySelections.value.clear()

            // Reset the markers
            state.activemarkers = null

            updateToast({
                label: null,
                message: null,
                active: null,
                markers: null
            })
            state.search = {value: terms}
        }

        /**
         * Update the map based on user selections
         *
         * @param {HTMLElement} e An HTMLElement instance
         */
        const select = (e, type) => {
            const element   = e.target
            const value     = (element instanceof HTMLSelectElement) ? element.value : element.getAttribute('value')
            let s           = null

            switch (type) {
                case 'category':
                    s = categorySelections
                    if(!s.value.has(value)) {
                        s.value.add(value)
                    }else{
                        s.value.delete(value)
                    }
                    break
                case 'region':
                    s = regionSelections
                    s.value.clear()
                    if (!!value) {
                        s.value.add(value)
                    } else {
                        s.value = new Set([...regions.value])
                    }
                    break
            }

            terms.categories    = [...categorySelections.value.values() ?? []]
            terms.regions       = (!!regionSelections.value?.size || !!regionSelections.value?.length) ? [...regionSelections.value.values()] :  [...regions.value ?? []]
            terms.values        = search.value?.value

            state.search = {value: terms}
        }

        /**
         * Filter markers against an array of keywords.
         *
         * Search terms can be full or partial keywords. This method will attempt
         * to match a Marker's Feature keywords property.
         *
         * @link https://github.com/joshjung/trie-search?tab=readme-ov-file#search-or-of-multiple-phrases
         * @param {SearchTerm} terms A SearchTerm instance
         * @returns {object} Returns an object literal containing matching results.
         */
        const filterMarkers = (t) => {
            // Filter Results
            const details = {
                terms:   isProxy(t) ? toRaw(t) : t,
                markers: state.markers,
                length:  0,
                action:  'search'
            }

            // Exit early if no search terms are provided
            if(!terms.all?.length && !terms.values?.length) {
                state.searchcomplete = details
                // state.emit('onSearchComplete', details)
                updateToast({
                    label: null,
                    message: null,
                    active: null,
                    markers: null
                })
                return
            }

            let results = []
            let filteredset = [...state.dataset ?? []]

            // console.clear()
            // Perform the keyword search]
            // console.log('filteredset', details.terms?.values, filteredset)

            const kw = getTrieIntance(filteredset)
            filteredset = (!!details.terms?.values) ? kw.search(details.terms?.values) : filteredset

            /**
             * Array match/search
             */
            // Category search
            filteredset = filteredset.filter(v => !!details.terms?.categories?.filter( x => v.categories.includes(x) )?.length)

            // Region search
            filteredset = filteredset.filter(v => !!details.terms?.regions?.filter( x => v.regions.includes(x) )?.length)

            // Filter by category FIRST, then region SECOND
            // let filteredset = [...state.dataset ?? []]
            // const fields = ['categories', 'regions']
            // fields.map( f => {
            //     const tr = getTrieIntance(filteredset)
            //     const terms = details.terms[f]
            //     filteredset = (!!terms?.length) ? tr.search(terms) : filteredset
            // })
            // results = [...filteredset]

            // console.clear()
            // console.group('SELECT');
            // console.log('filteredset', filteredset)
            // console.log('details', details)

            // Sort alphabetically, extract marker instance
            results = [...filteredset ?? []]
            results = results.sort((a,b) => a.title.localeCompare(b.title))
            results = [...results ?? []].map(item => item.marker)
            // console.log('results', results)
            // console.log(`${terms.friendly} found ${results.length} matches...`, results)
            // console.groupEnd();

            // Update details
            details.terms   = terms,
            details.markers = results,
            details.length  = results?.length ?? 0,

            state.searchcomplete = details

            return details
        }

        // +------------------------------------------------------------------------------------------+ //
        // EVENT HANDLERS
        // +------------------------------------------------------------------------------------------+ //
        /**
         * Handler search term changes
         */
        watch(() => state.search, (n, o) => {
            if (!!n) {
                filterMarkers(state.search?.value)
            }
        })

        watch(() => state.loading, (n, o) => {
            if (!!n) {
                console.log('Loading map data...', state.loading)
            }
        })

        /**
         * Search results handler
         */
        watch(() => state.searchcomplete, (n, o) => {
            if (!!n) {
                const details = state.searchcomplete
                let _markers = !!details.markers?.length ? details?.markers : state?.markers
                closeInfoWindows()
                clearMarkers()
                update(_markers)
                updateToast({
                    label:      details.length ? `Found ${details.length} matches` : null,
                    message:    details.length ? `${terms.friendly}...` : null,
                    active:     details.length ? true : null,
                    markers:    details?.markers?.length ? details.markers : null
                })

                if (anchor.value && details?.markers?.length) {
                    const m = [...details.markers]?.shift()
                    state.markerclick = {marker: m}
                    state.selection = {
                        id: m?.feature.getProperty('id')
                    }
                }
            }
        })

        /**
         * Show map marker details
         */
        watch(() => state.markerclick, (n, o) => {
            const marker = toRaw(state.markerclick)

            if(!!marker) {
                closeInfoWindows()
                show(marker)
            }
        })

        /**
         * Handle click events in info window
         */
        watch(() => state.infoWindowClick, (n, o) => {
            if (!!n) {
                console.log(state.infoWindowClick)
            }
        })

        /**
         * Handle map marker selections
         */
        watch(() => state.selection, (n, o) => {
            if (!!n) {
                const id = state.selection?.id
                const query = getQuery('readFeature')({id:id})
                if(id && query) {
                    const marker    = getMarkerByID(id)
                    state.loading = true
                    detail.value = null
                    api.post(endpoint.value, {...query}, axioscacheconfig).then( response => {
                        const data = {
                            ...getMarkerDataByMarker(marker) ?? {},
                            ...response.data?.data?.readFeature ?? {}
                        }

                        if (response.status == 200) {
                            display({
                                id:     id,
                                marker: marker,
                                data:   data,
                                html:   getFeatureCard(data)
                            })
                        }
                    }).catch(error => {
                        console.log(error, error.response)
                        state.loading = false
                    })
                }
            }
        })


        /**
         * Handle marker collection state change
         */
        watch(() => state.markers, (n, o) => {
            if (!!n) {
                if (state.markers?.length || !!state.markers?.size) {
                    state.loading = false
                }
            }
        })

        watch(data, (n, o) => {
            if (n) {
                const {layout}      = useNormalizeLayoutProps(data)
                const {ratios}      = useNormalizeRatioProps(data)
                const {grid, gap}   = useNormalizeGridProps(data)
                layoutprops.value   = {...layout.value}
                gridprops.value     = {...grid.value}

                // Mode
                mode.value          = layoutprops.value?.template

                // Default taxonomies
                defaultregions.value = new Set()
                defaultcategories.value = new Set()
                data.value?.taxonomies?.map( v => {
                    const key = v.typeid
                    const val = v.name
                    switch(key) {
                        case 7: // regions
                            defaultregions.value.add(val)
                            break;
                        default: // everything else is a term
                            defaultcategories.value.add(val)
                            break;
                    }
                })

                // Set the default term if from an anchor or querystring
                // if (term.value) {
                //     defaultcategories.value.add(term.value?.name)
                // }

                // console.log(term.value, data.value?.taxonomies, [...defaultcategories.value?.values()])
            }
        })

        // +------------------------------------------------------------------------------------------+ //
        // LIFECYCLE METHODS
        // +------------------------------------------------------------------------------------------+ //
        onBeforeMount(() => {
            const params = props.info ? JSON.parse(props?.info) : null

            // Static props
            mode.value = params?.mode
            term.value = params?.terms
            key.value = props?.key ?? store?.googlemaps_api_key

            if (params?.constructor == Object && !!params?.prefetch) {
                useComponentdata({...params, query: 'readPartnerMap', data: data})
            }else{
                if(!!props.config) {
                    data.value = (JSON.parse(props.config))?.props
                }
            }

            // Detect changes to the URL
            window.addEventListener('hashchange', (e) => {
                anchor.value = window.location.hash.replace('#', '')
            })

            // Capture any hash values in the URL
            anchor.value = window.location.hash.replace('#', '')
        })

        onMounted(() => {
            if (!document.querySelector('script#googlemapsjsapi')) {
                const url = `https://maps.googleapis.com/maps/api/js?key=${key.value}`
                const script = document.createElement('script')
                script.setAttribute('aysnc','')
                script.setAttribute('defer','')
                script.setAttribute('src', url)
                script.setAttribute('id','googlemapsjsapi')
                script.addEventListener('load', (e) => {
                    init()
                })
                document.head.append(script)
            }else{
                init()
            }
        })

        return {
            root,
            data,
            state,
            showcontent,
            showlinks,
            showtitle,
            wrappedtitle,
            layoutprops,
            gridprops,
            // loading,
            bounds,
            mapfilter,
            mapcontainer,
            search,
            input,
            inputreset,
            categories,
            showregions,
            regions,
            select,
            categorySelections,
            terms,
            selected,
            toast,
            detail,
            activedetail,
            drawer,
            dialog,
            dialogClose,
            esc
        }
    }
}
</script>

<style lang="scss">
@include foundation();
</style>
