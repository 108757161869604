<template>
    <Suspense>
        <template #default>
            <svg ref="root" v-bind="$attrs">
                <symbol :="item.symbolProps" v-for="(item, index) in symbols?.park" v-html="item.svg"></symbol>
                <symbol :="item.symbolProps" v-for="(item, index) in symbols?.landmark" v-html="item.svg"></symbol>
                <symbol :="item.symbolProps" v-for="(item, index) in symbols?.feature" v-html="item.svg"></symbol>
                <symbol :="item.symbolProps" v-for="(item, index) in symbols?.asset" v-html="item.svg"></symbol>
                <symbol :="item.symbolProps" v-for="(item, index) in symbols?.icon" v-html="item.svg"></symbol>
                <symbol :="item.symbolProps" v-for="(item, index) in symbols?.text" v-html="item.svg"></symbol>
                <g transform-origin="360 512">
                    <image x="0" y="0" width="720" height="1024" :href="base" />
                    <use v-for="(item, index) in symbols?.park" :="item.props" transform-origin="50% 50%"><title v-text="item?.debugTitle" /></use>
                    <use v-for="(item, index) in symbols?.landmark" :="item.props" transform-origin="50% 50%"><title v-text="item?.debugTitle" /></use>
                    <use v-for="(item, index) in symbols?.feature" :="item.props" transform-origin="50% 50%"><title v-text="item?.debugTitle" /></use>
                    <use v-for="(item, index) in symbols?.asset" :="item.props" transform-origin="50% 50%"><title v-text="item?.debugTitle" /></use>
                    <use v-for="(item, index) in symbols?.icon" :="item.props" transform-origin="50% 50%"><title v-text="item?.debugTitle" /></use>
                    <use v-for="(item, index) in symbols?.text" :="item.props" transform-origin="50% 50%"><title v-text="item?.debugTitle" /></use>
                </g>
            </svg>
        </template>
        <template #fallback>
            <MDSkeleton aspect-21-9>
                <MDBlocker active="true" cursor="progress" type="default" />
            </MDSkeleton>
        </template>
    </Suspense>
</template>

<script>
import { ref, computed, onBeforeMount, watch } from 'vue';
import { useStore } from '@/javascript/lib/store'
import MapBase from '@/assets/map-base.svg?url'
import MapSymbol from '@/javascript/components/MapSymbol'
import MDSkeleton from '@/components/MDSkeleton.ce.vue'
import MDBlocker from '@/components/MDBlocker.vue'

export default {
    name: "MapSVG",
    components: {MapBase, MDBlocker},
    props: {
        symbols: {
            type: Array,
            default() {
                null
            }
        },
        config: {
            type: Object,
            default() {
                return null
            }
        }
    },
    setup(props) {
        const data      = ref(null)
        const root      = ref(null)
        const store     = useStore()
        const symbols   = ref(null)
        const symbolcontent = ref(null)
        const base      = computed(() => (new URL(MapBase, import.meta.url))?.toString())

        /**
         * Monitor changes to store.svgmapdata
         */
        watch(() => store.svgmapdata, (n, o) => {
            if (!!store.svgmapdata) {
                // console.log(store.svgmapdata)
            }
        })

        /**
         * Handle click events for interactive elements in the map
         */
        watch(root, (n, o) => {
            if (n instanceof SVGElement) {
                root.value?.addEventListener('click', (e) => {
                    const el = e.target
                    const uuid = e.target.getAttribute('uuid') ?? null
                    if (el.hasAttribute('interactive') && uuid) {
                        store.svgmapdata = symbolcontent.value?.has(uuid) ? symbolcontent.value?.get(uuid) : null
                    }
                })
            }
        })

        /**
         * Update this component when it receives a colletion of symbol data
         */
        watch(() => props.symbols, (n, o) => {
            // Store a lookup table of all interactive elements, ignore non-interactive elments
            symbolcontent.value = new Map()

            // Create new MapSymbol instances to flatten the properties of the symbol
            const s = [...props.symbols ?? []]?.map( v => new MapSymbol(v.props))
            const g = {};
            s?.map( v => {
                if (!(v.type in g)) {
                    g[v.type] = []
                }

                g[v.type].push(v)

                if (v?.interactive) {
                    symbolcontent.value?.set(v?.uuid, v)
                }
            })

            symbols.value = g
        })

        return {
            data,
            root,
            symbols,
            base
        }
    }
}
</script>

<style lang="scss">
@include foundation();
</style>
