<template>
    <location rs-grid xs-1 gap-12 :location="data?.Title" :headings="headings">
        <h4 scale="xs" weight="900" gutters="0" caps>{{ data?.Title }}</h4>
        <mailingaddress rs-grid xs-1 gap-6 scale="small" class="h-adr">
            <street v-if="!!data?.Address1" class="p-street-address">{{ data?.Address1 }}</street>
            <street v-if="!!data?.Address2" class="p-street-address">{{ data?.Address2 }}</street>
            <street v-if="!!data?.Address3" class="p-street-address">{{ data?.Address3 }}</street>
            <street v-if="!!data?.Unit" class="p-street-address">{{ data?.Unit }}</street>
            <city v-if="!!data?.City" class="p-locality">{{ data?.City }}, <region v-if="!!data?.Region" class="p-region">{{ data?.Region }}</region></city>
            <postalcode v-if="!!data?.PostalCode" class="p-postal-code">{{ data?.PostalCode }}</postalcode>
            <country v-if="!!data?.County" class="p-country-name">{{ data?.County }}</country>
        </mailingaddress>
        <contactinfo rs-grid xs-1 gap-6 scale="small">
            <info rs-grid>
                <icon mdicon>phone</icon>
                <a :href="`tel:${data?.Phone ?? data?.TollFree}`">{{ data?.Phone ?? data?.TollFree }}</a>
            </info>
            <info rs-grid>
                <icon mdicon>email</icon>
                <a :href="`mailto:${data?.Email}?subject=${data?.Title}`" :title="`Contact our ${data?.Title}`">Contact Us</a>
            </info>
        </contactinfo>
    </location>
</template>

<script>
import { ref, computed, onBeforeMount, onMounted } from 'vue';

export default {
    name: "Address",
    components: {},
    props: {
        heading: {
            type: Boolean,
            default() {
                return false
            }
        },
        config: {
            type: Object,
            default() {
                return null
            }
        }
    },
    setup(props) {
        const data = ref(null)
        const headings = ref(false)
        onBeforeMount(() => {
            if((props.config?.constructor !== Object)) {
                data.value = JSON.parse(props.config)
            }else{
                data.value = props.config
            }
        })
        onMounted(() => {
            headings.value = props.heading
        })
        return {
            data,
            headings
        }
    }
}
</script>

<style lang="scss">
@include foundation();
</style>
