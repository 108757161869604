<template>
    <footer ref="root" rs-elemental="footer" rs-grid :="layoutprops" aria-label="Footer" role="contentinfo">
        <Suspense>
            <template #default>
                <template v-if="!!data?.components?.length">
                    <rs-grid gap-24 span-sm-12  xxs-1 sm-10 businesscontent>
                        <UIContent v-for="(item, index) in data?.components" :key="`block-${item?.id}`" :config="item.props" />
                        <uicontent supporters rs-grid span-xs-1 span-sm-10 span-orientation-landscape id="uicontent-supporters">
                            <uicontenttitle rs-griditem span-xs-1>
                                <MDText tag="h2" wrap="true" gutters="0" scale="xs" display="inline" variant-align="left" variant="normal" weight="700" caps casing="display-sm" center label="Partnerships"></MDText>
                            </uicontenttitle>
                            <uicontentaction rs-griditem span-xs-1>
                                <a v-for="(item, index) in supporters" :key="item.key" :href="item?.url" :target="item?.openinnewwindow ? '_blank' : null" supporter :supporter-key="item.key" >
                                    <span v-text="item?.title" />
                                </a>
                            </uicontentaction>
                        </uicontent>
                    </rs-grid>

                    <rs-grid gap-6 span-sm-12  xxs-1 sm-10 v-if="contentprops?.copyright || contentprops?.credit" copyright>
                        <rs-griditem center-start span-xs-1 span-sm-10>
                            <span v-if="contentprops?.copyright" scale="caption" caps class="footer--copyright" v-text="contentprops?.copyright"></span>
                            <template v-if="links?.length">
                                <a v-for="(item, index) in links" :key="item.key" :href="item?.url" caps scale="caption" utility v-text="item?.title"></a>
                            </template>
                        </rs-griditem>
                    </rs-grid>
                    <MDFab />
                </template>
                <MDSkeleton aspect-1-1 v-else error="true" spin />
            </template>
            <template #fallback>
                <MDSkeleton>
                    <MDBlocker active="true" cursor="progress" type="default" />
                </MDSkeleton>
            </template>
        </Suspense>
    </footer>
</template>

<script>
import { ref, onBeforeMount, watch } from 'vue'
import { useComponentdata, useNormalizeComponentProps } from '@/javascript/lib/composables'
import IconMenu from '@/components/IconMenu.ce.vue'
import MDText from '@/components/MDText.vue'
import MDBlocker from '@/components/MDBlocker.vue'
import MDSkeleton from '@/components/MDSkeleton.ce.vue'
import MDFab from '@/components/MDFab.ce.vue'
import UIContent from '@/components/UIContent.ce.vue'

export default {
    name: "AppFooter",
    components: {IconMenu, UIContent, MDFab, MDText, MDBlocker, MDSkeleton},
    props: {
        config: {
            type: String,
            default() {
                return null
            }
        },
        info: {
            type: String,
            default() {
                return null
            }
        },
        active: {
            type: Boolean,
            default() {
                return false;
            }
        }
    },
    setup(props) {
        const root          = ref(null)
        const data          = ref(null)
        const links         = ref(null)
        const supporters    = ref(null)
        const layoutprops   = ref(null)
        const contentprops  = ref(null)

        watch(data, (newvalue, oldvalue) => {
            const {props}       = useNormalizeComponentProps(data)
            layoutprops.value   = {...props.value}
            if (data.value?.layout?.contentprops) {
                contentprops.value = {}
                data.value?.layout?.contentprops?.map( v => {
                    contentprops.value[v.key] = v.value
                });
            }

            // Supporter vs Footer links
            data.value?.linkgroups?.map( (v) => {
                switch(v.key) {
                    case 'supporters':
                        supporters.value = [...v.links ?? []]
                        break;
                    case 'footer':
                    default:
                        links.value = [...v.links ?? []]
                        break;
                }
            })
        })

        onBeforeMount(() => {
            const params = props.info ? JSON.parse(props?.info) : null
            if (params?.constructor == Object && !!params?.prefetch) {
                useComponentdata({...params, query: 'readGlobalComponent', data: data})
            }else{
                if(!!props.config) {
                    data.value = (JSON.parse(props.config))?.props
                }
            }
        })

        return {
            root,
            contentprops,
            layoutprops,
            links,
            supporters,
            data
        }
    }
}
</script>

<style lang="scss">
@include foundation();
</style>
