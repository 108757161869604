<template>
    <Suspense>
        <template #default>
            <rs-container ref="root" rs-elemental="uielementaltaxonomymosaic" :="layoutprops">
                <rs-grid>
                    <template v-if="template == 'tile_taxonomy-a'">
                    <rs-griditem v-for="(item, index) in mosaics" :key="index" content stackable>
                        <content stackable-item>
                            <div class="content-wrapper">
                                <rs-heading v-if="item.title" elastic>
                                    <h3 weight="regular" casing="display-xs" gutters="0" caps v-html="item.title"></h3>
                                </rs-heading>
                                <rs-actions v-if="item.links">
                                    <a v-for="(linkItem, linkIndex) in item.links" :key="linkItem.key || linkIndex" :href="linkItem.url" :target="item?.openinnewwindow ? '_blank' : null" :index="index" :title="item?.title" v-text="item?.title"></a>
                                </rs-actions>
                            </div>
                        </content>
                        <template v-if="item.image?.id">
                            <Picture stackable-item :config="item.image" :alt="item.image?.alt ?? item.title" />
                        </template>
                    </rs-griditem>
                    <rs-griditem>
                        <content stackable-item>
                            <rs-heading elastic>
                                <h3 weight="regular" casing="display-xs" gutters="0" caps>More</h3>
                            </rs-heading>
                            <rs-actions v-if="showlinks">
                                <a v-for="(item, index) in links" :href="item?.url" :target="item?.openinnewwindow ? '_blank' : null" :key="item?.key" :index="index" :title="item?.title" v-text="item?.title"></a>
                            </rs-actions>
                        </content>
                    </rs-griditem>
                    </template>
                </rs-grid>
            </rs-container>
        </template>
        <template #fallback>
            <MDSkeleton aspect-21-9>
                <MDBlocker active="true" cursor="progress" type="default" />
            </MDSkeleton>
        </template>
    </Suspense>
</template>

<script>
import { ref, computed, watch, onBeforeMount } from 'vue';
import { useStore } from '@/javascript/lib/store'
import { useWrappedString , useComponentdata, useNormalizeComponentProps, useNormalizeContentProps } from '@/javascript/lib/composables'
import MDButton from '@/components/MDButton.ce.vue'
import MDBlocker from '@/components/MDBlocker.vue'
import MDSkeleton from '@/components/MDSkeleton.ce.vue'
import Picture from '@/components/Picture.ce.vue'

export default {
    name: "UIElementalTaxonomyMosaic",
    components: {MDButton, MDSkeleton, MDBlocker, Picture},
    props: {
        info: {
            type: String,
            default() {
                return null
            }
        },
        config: {
            type: Object,
            default() {
                return null
            }
        }
    },
    setup(props) {
        const root          = ref(null)
        const store         = useStore()
        const data          = ref(null)
        const layoutprops   = ref(null)
        const contentprops  = ref(null)
        const mosaics       = ref(null)
        const template      = ref(null)
        const links         = ref(null)
        const currentproperties = ref(null)
        const showcontent   = computed(() => data.value?.display?.displaycontent)
        const showtitle     = computed(() => data.value?.display?.showtitle)
        const showlinks     = computed(() => data.value?.display?.displaylinks && data.value?.links?.length)
        const wrappedtitle  = computed(() => {
            return useWrappedString(data.value?.title, [...data.value?.focustext] ?? [])
        })

        watch(root, (newvalue, oldvalue) => {
            if(newvalue instanceof HTMLElement) {
                store.observe(newvalue)
            }
        })

        watch(data, (newvalue, oldvalue) => {
            if (newvalue) {
                const {props}       = useNormalizeComponentProps(data)
                layoutprops.value   = {...props.value}
                const r = new RegExp('(^[0-9]+)')
                if (!!layoutprops.value?.label && !(r.test(layoutprops?.value?.label))) {
                    layoutprops.value[layoutprops.value.label] = ''
                    delete layoutprops.value.label
                }

                if (data.value?.layout?.contentprops) {
                    contentprops.value = {}
                    data.value?.layout?.contentprops?.map( v => {
                        contentprops.value[v.key] = v.value
                    });
                }

                // Assign components to mosaics ref
                mosaics.value = [...data.value.components ?? []]?.map( v => v.props)
                links.value = [...data.value?.links ?? []]

                console.group('DATA')
                console.log('mosaics.value', mosaics.value)
                console.log('links.value', links.value)
                console.groupEnd()


                currentproperties.value = { ...data.value.components?.[0]?.props ?? {}}

                const { properties } = useNormalizeContentProps(currentproperties)
                contentprops.value   = { ...properties.value ?? {} }
                template.value       = contentprops.value.template
            }
        })

        onBeforeMount(() => {
            const params = props.info ? JSON.parse(props?.info) : null
            if (params?.constructor == Object && !!params?.prefetch) {
                useComponentdata({...params, query: 'readMosaic', data: data})
            }else{
                if(!!props.config) {
                    data.value = (JSON.parse(props.config))?.props
                }
            }
        })

        return {
            root,
            data,
            layoutprops,
            contentprops,
            template,
            mosaics,
            links,
            showtitle,
            showcontent,
            showlinks,
            wrappedtitle
        }
    }
}
</script>

<style lang="scss">
@include foundation();
</style>
