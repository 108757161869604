<template>
    <marker v-once class="marker marker--root md-typography md-typography--root" :data-id="data?.id" :data-featured="data?.featured">
        <markercontent :link="data?.link" class="marker--wrapper" :data-id="data?.id"  :data-featured="data?.featured">
            <media v-if="image?.url">
                <img :src="image.url" data-defer-type="src" class="non-deferred" :alt="data?.title" />
            </media>
            <markerdetail class="marker--details">
                <title>{{ data?.title }}</title>
            </markerdetail>
        </markercontent>
    </marker>
</template>

<script>
import { ref, onBeforeMount } from 'vue'

export default {
    name: "Marker",
    components: {},
    props: {
        config: {
            type: Object,
            default() {
                return null
            }
        }
    },
    setup(props, {emit}) {
        const data = ref(null)
        const image = ref(null)

        onBeforeMount(() => {
            data.value  = props.config.feature.getProperty('markerdata')
            image.value = data.value?.image
        })

        return {
            data,
            image
        }
    }
}
</script>

<style lang="scss">
@include foundation();
</style>
