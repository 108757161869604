<template>
    <div class="detailcard view view--root md-typography md-typography--root" :data-id="data.id">
        <div class="view--wrapper">
            <div class="view--header">
                <button class="view-close" title="Close"><span class="md-icon">close</span></button>
                <template v-if="data.image">
                <Picture :config="data.image" :alt="data.image?.alt ?? data?.title" class="view--media" />
                </template>
                <h4 class="view--title md-typography-h5" casing="display-xs" branded><a :href="data.url">{{ data.title }}</a></h4>
                <ul class="view--meta">
                    <li class="meta--item meta-item--address" scale="body-sm">
                        <template v-if="address">
                            <icon class="md-icon">place</icon> <a :href="maplink" rel="nofollow" class="meta-label" v-html="address" />
                        </template>
                        <template v-else>
                            <icon class="md-icon">place</icon> <span class="meta-label" v-html="address" />
                        </template>
                    </li>

                    <li v-if="data.phone" class="meta--item meta-item--phone" scale="body-sm">
                        <span class="md-icon">phone</span> <a :href="`tel:${data.phone}`" rel="nofollow" class="meta-label" :title="`Call ${data.phone}`">{{ data.phone }}</a>
                    </li>

                    <li v-if="data.url" class="meta--item meta-item--url" scale="body-sm">
                        <span class="md-icon">link</span> <a :href="data.url" rel="nofollow noreferrer noopner" class="meta-label" target="_blank" :title="`Visit ${data.title} online`">Visit {{ data.title }} online</a>
                    </li>

                    <li v-if="maplink" class="meta--item meta-item--dirctions" scale="body-sm">
                        <span class="md-icon">navigation</span> <a :href="maplink" rel="nofollow noreferrer noopner" class="meta-label" target="_blank">Directions</a>
                    </li>
                </ul>
            </div>
            <div class="view--details md-typography-body-2">
                <div v-if="data.socials.length" class="view--socials padded-all bordered-top-bottom">
                    <a v-for="(item, index) in data.socials" :key="`social-${item.platform.toLowerCase()}`" :href="item?.url" rel="nofollow noreferrer noopner" :class="`social--item social-item--${item.platform.toLowerCase()} ${item.platform.toLowerCase()}`" target="_blank">
                        <span :class="`social-icon ${item.platform.toLowerCase()}`">{{ data.platform }}</span> <span class="social-label">{{ data.platform }}</span>
                    </a>
                </div>

                <div v-if="data.description" class="view--content padded-all">
                    <div v-if="data.description" v-html="data.description" scale="body-mm"></div>

                    <template v-if="data.dishes.length">
                        <MDText label="Recommended Dishes:" branded scale="h3"/>
                        <ul class="dishes dishes--root">
                            <li v-for="(item, index) in data.dishes" :key="`dish-${index}`">{{ item }}</li>
                        </ul>
                    </template>

                    <template v-if="data.tips">
                        <MDText label="Spice Trail Tip:" branded scale="h3"/>
                        {{ data.tips }}
                    </template>
                </div>
            </div>

            <ul v-if="data?.trie?.regions.length" class="view--taxonomy md-typography-caption padded-all bordered-top">
                <li v-for="(item, index) in data?.trie?.regions" class="taxonomy--term"><span class="md-icon">map</span><span class="term-label">{{ item }}</span></li>
            </ul>

            <div v-if="data?.gallery?.length" class="view-gallery">
                <MDText label="Gallery" scale="h6" class="view-gallery--heading" />
                <div class="view-gallery--wrapper">
                    <a v-for="(item, index) in data.gallery" :href="item?.lg" class="view-gallery--item">
                        <icon class="md-icon">add</icon>
                        <img :src="item.sm" loading="lazy" :alt="`${item.alt ?? item.title ?? 'Gallery Asset'}`" />
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ref, computed, onBeforeMount } from 'vue';
import MDText from '@/components/MDText.vue';
import Picture from '@/components/Picture.ce.vue'

export default {
    name: "Detail",
    components: {MDText, Picture},
    props: {
        config: {
            type: Object,
            default() {
                return null
            }
        }
    },
    setup(props) {
        const root      = ref(null)
        const data      = ref(null)
        const address   = computed(() => {
            let a = [
                    data.value?.title,
                    data.value?.address,
                    data.value?.postalCode ? `${data.value.province}, ${data.value?.postalCode}` : data.value.province
                ].filter(n => n)
            return a.join("<br>")
        })
        const maplink   = computed(() => {
            let a = [
                    data.value?.title,
                    data.value?.address,
                    data.value?.postalCode ? `${data.value.province}, ${data.value?.postalCode}` : data.value.province,
                    'Canada'
                ].filter(n => n)
            const addr = encodeURI(a.join(' '))
            return `https://www.google.com/maps/dir/?api=1&origin=&destination=${addr}&travelmode=driving`
        })

        onBeforeMount(() => {
            data.value = props.config
        })

        return {
            root,
            data,
            address,
            maplink
        }
    }
}
</script>

<style lang="scss">
@include foundation();
</style>
