<template>
    <skeleton v-bind="$attrs" :error="error" elastic>
        <slot v-if="!error"></slot>
        <icon v-else mdicon>settings</icon>
    </skeleton>
</template>

<script>
import { computed, onBeforeMount } from 'vue';

export default {
    name: "MDSkeleton",
    components: {},
    props: {
        error: {
            type: Boolean,
            default() {
                return false
            }
        },
        config: {
            type: Object,
            default() {
                return null
            }
        }
    },
    setup(props) {
        onBeforeMount(() => {
        })
        return {
        }
    }
}
</script>

<style lang="scss">
@include foundation();
</style>

