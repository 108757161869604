<template>
    <slide ref="root" rs-elemental="uielementalframe" :="layoutprops" collapsetop="1" collapsebottom="1" :style="slidestyle" rs-grid>
        <rs-media>
            <Picture :config="data?.image" :alt="data?.image?.alt ?? data?.title" />
        </rs-media>

        <rs-container v-if="showtitle || showcontent || showlinks">
            <rs-heading v-if="showtitle">
                <h2 scale="xl" weight="regular" casing="display-sm" gutters="0" caps v-html="wrappedtitle"></h2>
            </rs-heading>

            <rs-content v-if="showcontent" v-html="data?.content"></rs-content>

            <rs-actions v-if="showlinks">
                <MDButton v-for="(item, index) in data?.links" tag="a" :href="item?.url" :target="item?.openinnewwindow ? '_blank' : null" :key="item?.key" :index="index" :label="item?.title" :type="index == 0 ? 'contained' : 'outlined'" :class="item?.css" chunky />
            </rs-actions>
        </rs-container>
    </slide>
</template>

<script>
import { ref, computed, watch, onBeforeMount } from 'vue';
import Picture from '@/components/Picture.ce.vue'
import { useWrappedString } from '@/javascript/lib/composables'

export default {
    name: "UIElementalFrame",
    components: {Picture},
    props: {
        config: {
            type: Object,
            default() {
                return null
            }
        }
    },
    setup(props) {
        const data          = ref(null)
        const image         = ref(null)
        const layoutprops   = ref(null)
        const showcontent   = computed(() => data.value?.display?.displaycontent)
        const showtitle     = computed(() => data.value?.display?.showtitle)
        const showlinks     = computed(() => data.value?.display?.displaylinks)
        const wrappedtitle  = computed(() => {
            return useWrappedString(data.value?.title, [...data.value?.focustext] ?? [])
        })

        const slidestyle = ref({
            backgroundColor: null
        })

        watch(data, (newvalue, oldvalue) => {
            if (newvalue) {
                layoutprops.value   = {...data.value?.grid ?? {},...data.value?.ratios, ...data.value?.layout}
                image.value         = {...data.value?.image}
                slidestyle.value.backgroundColor = data.value?.display?.backgroundcolor
            }
        })

        onBeforeMount(() => {
            if(props.config?.constructor !== Object) {
                data.value = JSON.parse(props.config)?.props
            }else{
                data.value = props.config?.props
            }
        })

        return {
            data,
            layoutprops,
            showtitle,
            showcontent,
            showlinks,
            wrappedtitle,
            image,
            slidestyle
        }
    }
}
</script>

<style lang="scss">
@include foundation();
</style>
