<template>
    <Suspense>
        <template #default>
            <rs-container ref="root" rs-elemental="uielementaleventslist" :="layoutprops">
                <template v-if="loading">
                    <MDSkeleton aspect-16-9 error="true" spin />
                </template>
                <template v-else>
                    <rs-grid xxs-1 sm-12>
                        <rs-griditem span-xs-1 span-sm-12 filtercontainer v-if="showfilters">
                            <form ref="filterform" filters @change.prevent.stop="filterhandler" @submit.prevent="formsubmit">
                                <label v-for="(item, index) in datefilters?.values()" :key="`month-${item?.getMonth() + 1}`" :for="item?.toLocaleString('default', { month: 'short' })" :="selected(item?.getMonth())"  easing>
                                    <MDText tag="span" :label="item?.toLocaleString('default', { month: 'short' })" scale="subtitle-lg" branded />
                                    <input type="checkbox" :id="item?.toLocaleString('default', { month: 'short' })" name="months" :value="`${item?.getMonth()}`">
                                </label>
                                <button type="reset" @click.prevent.stop="inputreset" title="Show All" noappearance easing><icon>restart_alt</icon></button>
                            </form>
                        </rs-griditem>
                        <template v-if="!!events?.length">
                            <EventCard v-for="(item, index) in eventslist" :id="`event-${item?.props?.id}`" :key="`event-${index}`" v-memo="eventslist" :config="item"></EventCard>
                        </template>
                        <template v-if="loading">
                            <MDSkeleton aspect-16-9 error="true" spin />
                        </template>
                    </rs-grid>
                </template>
            </rs-container>
        </template>
        <template #fallback>
            <MDSkeleton aspect-16-9>
                <MDBlocker active="true" cursor="progress" type="default" />
            </MDSkeleton>
        </template>
    </Suspense>
</template>

<script>
import { ref, isProxy, toRaw, computed, watch, onBeforeMount } from 'vue';
import { useStore } from '@/javascript/lib/store'
import { useWrappedString , useComponentdata, useNormalizeComponentProps, useNormalizeContentProps } from '@/javascript/lib/composables'
import MDButton from '@/components/MDButton.ce.vue'
import MDBlocker from '@/components/MDBlocker.vue'
import MDText from '@/components/MDText.vue'
import MDSkeleton from '@/components/MDSkeleton.ce.vue'
import EventCard from '@/components/EventCard.vue'
import TrieSearch from 'trie-search'

export default {
    name: "UIElementalEventsList",
    components: {MDButton, MDSkeleton, MDBlocker, MDText, EventCard},
    props: {
        info: {
            type: String,
            default() {
                return null
            }
        },
        config: {
            type: Object,
            default() {
                return null
            }
        }
    },
    setup(props) {
        const root              = ref(null)
        const store             = useStore()
        const data              = ref(null)
        const filterform        = ref(null)
        const resetbutton       = ref(null)
        const layoutprops       = ref(null)
        const contentprops      = ref(null)
        const events            = ref([])
        const eventslist        = ref([])
        const rendergroups      = ref(true)
        const eventgroups       = ref(null)
        const loading           = ref(true)
        const regions           = ref(null)
        const categories        = ref(null)
        const fitlerfields      = ref(null)
        const datefilters       = ref(null)
        const selectedmonths    = ref([])
        const selected          = (value) => {
            return !![...selectedmonths.value?.values() ?? []]?.includes(value) ? {selected: ''} : {selected: null}
        }
        const formsubmit        = (e) => {
            e.preventDefault()
        }
        const dataset           = ref(null)
        const links             = ref(null)
        const delay             = ref(640)
        const showfilters       = ref(true)
        const showcontent       = computed(() => data.value?.display?.displaycontent)
        const showtitle         = computed(() => data.value?.display?.showtitle)
        const showlinks         = computed(() => data.value?.display?.displaylinks && data.value?.links?.length)
        const wrappedtitle      = computed(() => {
            return useWrappedString(data.value?.title, [...data.value?.focustext] ?? [])
        })

        const inputreset = (e) => {
            if (filterform.value instanceof HTMLFormElement) {
                filterform.value.reset()
                resetlist()
                selectedmonths.value = []
                selected()
                eventslist.value = [...events.value]
            }
        }

        const resetlist = () => {
            [...root.value?.querySelectorAll('rs-griditem[eventcard]')]?.map(v => {
                v?.remove()
            })
        }

        const filterhandler = (e) => {
            const frm   = e.target?.closest('form')

            if (frm instanceof HTMLFormElement) {
                selectedmonths.value    = [...(new FormData(frm)).values()]?.map( v => parseInt(v, 10)).sort()
                loading.value           = true
                eventslist.value        = []
                resetlist()

                if (selectedmonths.value?.length) {
                    let __to = null
                    __to = setTimeout(() => {
                        let results = []
                        selectedmonths.value?.map(m => {
                            if (eventgroups.value?.has(m)) {
                                results = [...eventgroups.value?.get(m)]
                            }
                        })
                        eventslist.value = results
                        loading.value = false
                        clearTimeout(__to)
                    }, delay.value)

                } else {
                    eventslist.value = [...events.value]
                    loading.value    = false
                }
            }
        }

        watch(root, (n, o) => {
            if(n instanceof HTMLElement) {
                store.observe(n)
            }
        })

        watch(data, (n, o) => {
            if (n) {
                const {props}       = useNormalizeComponentProps(data)
                layoutprops.value   = {...props.value}
                const r = new RegExp('(^[0-9]+)')
                if (!!layoutprops.value?.label && !(r.test(layoutprops?.value?.label))) {
                    layoutprops.value[layoutprops.value.label] = ''
                    delete layoutprops.value.label
                }

                const { properties } = useNormalizeContentProps(data)
                contentprops.value   = { ...properties.value ?? {} }

                links.value = [...data.value?.links ?? []]

                // Assign components to events ref
                eventslist.value = events.value = data.value.components

                // Intialize our dataset ref
                dataset.value = []

                // Group events by Year Month
                if (!!rendergroups.value) {
                    eventgroups.value = new Map()
                    eventslist.value?.map( v => {
                        const {properties}  = useNormalizeContentProps(v?.props ?? {})
                        const d = new Date(properties.value?.startdatetime)
                        const y = d?.getFullYear()
                        const m = d?.getMonth()
                        if (!!m) {
                            if (!eventgroups.value?.has(m)) {
                                eventgroups.value?.set(m, [])
                            }
                            eventgroups.value?.get(m).push(v)
                        }
                    } )
                }

                const months = [...Array(12).keys()]
                datefilters.value = new Map()
                months.map( v => {
                    const m = v
                    const d = new Date()
                    d.setMonth(m, 1)
                    datefilters.value?.set(m, d)
                })

                loading.value = false
            }
        })

        onBeforeMount(() => {
            const params = props.info ? JSON.parse(props?.info) : null
            if (params?.constructor == Object && !!params?.prefetch) {
                useComponentdata({...params, query: 'readListingContent', data: data})
            }else{
                if(!!props.config) {
                    data.value = (JSON.parse(props.config))?.props
                }
            }
        })

        return {
            root,
            data,
            regions,
            categories,
            layoutprops,
            contentprops,
            events,
            eventslist,
            links,
            showfilters,
            showtitle,
            showcontent,
            showlinks,
            wrappedtitle,
            filterhandler,
            loading,
            datefilters,
            selectedmonths,
            selected,
            inputreset,
            filterform,
            formsubmit
        }
    }
}
</script>

<style lang="scss">
@include foundation();
</style>
