<template>
    <template v-if="!inline">
        <rs-grid v-bind="$attrs" v-if="!!links">
            <component :is="component" v-for="(item, index) in links" :n="padindex(index)" :type="type" :key="`link-${item.key}`" :config="item" type="primary" casing="nav" rs-griditem />
        </rs-grid>
    </template>
    <template v-else>
        <rs-grid v-bind="$attrs" v-if="!!links">
            <component :is="component" v-for="(item, index) in links" :n="padindex(index)" :type="type" :key="`link-${item.key}`" :config="item" type="secondary" casing="nav-inline" rs-griditem />
        </rs-grid>
    </template>
</template>

<script>
import { ref, computed, onBeforeMount } from 'vue';
import AppbarNavItem from '@/components/AppbarNavItem.ce.vue'
import SocialNavItem from '@/components/SocialNavItem.ce.vue'
import { usePadIndex } from '@/javascript/lib/composables'

export default {
    name: "AppbarLinks",
    components: {AppbarNavItem, SocialNavItem},
    props: {
        type: {
            type: String,
            default() {
                return null
            }
        },
        inline: {
            type: Boolean,
            default() {
                return null
            }
        },
        config: {
            type: Object,
            default() {
                return null
            }
        }
    },
    setup(props, {attrs}) {
        const links     = ref(null)
        const component = computed(() => {
            switch(props.type) {
                case 'primary':
                case 'utility':
                case 'secondary':
                    return 'AppbarNavItem'
                case 'social':
                    return 'SocialNavItem'
                default:
                    return null
            }
        })
        const padindex = (v) => {
            return usePadIndex(v)
        }
        onBeforeMount(() => {
            links.value =  props.config
        })
        return {
            component,
            links,
            padindex
        }
    }
}
</script>
