<template>
    <rs-griditem span-xs-1>
        <story :title="data?.title" :id="`${data?.id}`" alignment="bottom left" aligncontent="left">
            <rs-media ref="media" :style="slidestyle" :cta="`Read More`" stackable rounded radius-xs>
                <a v-for="(item, index) in data?.links" :href="item?.url" :key="item?.key" :index="index" :class="item?.css" stackable-item>
                    <Picture :config="image" :alt="image?.alt ?? data?.title" easing />
                </a>
            </rs-media>
            <content>
                <rs-heading>
                    <MDText tag="overline" v-if="details" overline scale="body-xs" caps weight="700" :label="details" />
                    <h2 heading ref="heading" scale="lg" weight="400" casing="display-xs" gutters="0" caps><a :href="link?.url" v-html="data?.title"></a></h2>
                </rs-heading>
                <rs-content v-if="showcontent">
                    <p scale="body-sm" gutters="0" v-html="data?.content"></p>
                </rs-content>
                <rs-actions>
                    <MDButton v-for="(item, index) in data?.links" tag="a" :href="item?.url" :target="item?.openinnewwindow ? '_blank' : null" :key="item?.key" :index="index"  :label="`Read More`" :type="index == 0 ? 'contained' : 'outlined'" :class="item?.css" contained maxed solid />
                </rs-actions>
            </content>
        </story>
    </rs-griditem>
</template>

<script>
import { ref, computed, watch, onBeforeMount } from 'vue';
import { useNormalizeLayoutProps, useNormalizeGridProps, useNormalizeRatioProps, useNormalizeContentProps } from '@/javascript/lib/composables'
import Picture from '@/components/Picture.ce.vue'
import MDButton from '@/components/MDButton.ce.vue'
import MDText from '@/components/MDText.vue'

export default {
    name: "StoryCard",
    components: {Picture, MDButton, MDText},
    props: {
        config: {
            type: Object,
            default() {
                return null
            }
        }
    },
    setup(props) {
        const data          = ref(null)
        const image         = ref(null)
        const media         = ref(null)
        const layoutprops   = ref(null)
        const contentprops  = ref(null)
        const link          = ref(null)
        const details       = computed(() => `${contentprops?.value?.readingtime} • ${contentprops?.value?.author}`)
        const showcontent   = computed(() => data.value?.display?.displaycontent)
        const showtitle     = computed(() => data.value?.display?.showtitle)
        const showlinks     = computed(() => data.value?.display?.displaylinks)

        const slidestyle = ref({
            backgroundColor: null
        })

        watch(data, (newvalue, oldvalue) => {
            if (newvalue) {
                const {props}       = useNormalizeLayoutProps(data)
                const {grid}        = useNormalizeGridProps(data)
                const {ratios}      = useNormalizeRatioProps(data)
                const {properties}  = useNormalizeContentProps(data)

                contentprops.value = {...properties?.value ?? {}}
                layoutprops.value   = {...props?.value ?? {}, ...grid?.value ?? {}, ...ratios?.value ?? {}}

                image.value         = {...data.value?.image}
                slidestyle.value.backgroundColor = data.value?.display?.backgroundcolor
                link.value = [...data.value?.links ?? []]?.shift()
            }
        })

        onBeforeMount(() => {
            if(props.config?.constructor !== Object) {
                data.value = JSON.parse(props.config)?.props
            }else{
                data.value = props.config?.props
            }
        })

        return {
            data,
            details,
            layoutprops,
            contentprops,
            showtitle,
            showcontent,
            showlinks,
            image,
            slidestyle,
            link,
            media
        }
    }
}
</script>
