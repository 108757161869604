<template>
    <component :is="tag" :href="href" ref="button" scale="button" :index="index" :title="label" mdbutton rounded>
        <template v-if="icon && type == 'icon-only'">
            <icon v-text="icon"></icon>
        </template>
        <template v-else-if="icon">
            <icon v-if="icon" v-text="icon"></icon>
            <MDText tag="mdbuttonlabel" scale="button" v-text="label"></MDText>
        </template>
        <template v-else>
            <MDText tag="mdbuttonlabel" scale="button" v-text="label"></MDText>
        </template>
        <component v-for="(component, index) in components" :key="index" :is="component" />
    </component>
</template>

<script>
import { ref, computed, onBeforeMount, onMounted } from 'vue'
import MDRipple from '../components/MDRipple.vue';
import MDText from '../components/MDText.vue'

export default {
    name: "MDButton",
    components: {
        MDText
    },
    props: {
        label: {
            type: String,
            default() {
                return 'MDButton'
            }
        },
        tag: {
            type: String,
            default() {
                return 'button'
            }
        },
        href: {
            type: String,
            default() {
                return null
            }
        },
        type: {
            type: String,
            default() {
                return 'text'
            }
        },
        index: {
            type: String,
            default() {
                return null
            }
        },
        icon: {
            type: String,
            default() {
                return null
            }
        }
    },
    setup( props ) {
        const components = ref([])
        const button = ref(null)
        const specialbuttons = ref(['chip','chip-outlined','chip-icon','chip-outlined-icon'])

        return  {
            specialbuttons,
            button,
            components
        }
    }
}
</script>

<style lang="scss">
@include foundation();
</style>
