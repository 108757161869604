<template>
    <fab v-bind="$attrs" title="Back To Top" tabindex="-1" rs-grid center-center easing @click.prevent.stop="clickhandler">
        <icon>arrow_upward</icon>
    </fab>
</template>

<script>
import { computed, onBeforeMount } from 'vue';

export default {
    name: "MDFab",
    components: {},
    props: {
        config: {
            type: Object,
            default() {
                return null
            }
        }
    },
    setup(props) {
        const clickhandler = (e) => {
            const offset    = 0;
            window.requestAnimationFrame(() => {
                scroll({
                    top: offset,
                    behavior: 'smooth'
                });
            });
        }

        onBeforeMount(() => {
        })
        return {
            clickhandler
        }
    }
}
</script>

<style lang="scss">
@include foundation();
</style>

