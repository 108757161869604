<template>
    <rs-griditem v-if="data?.id" v-bind="$attrs" assets gap-0  xxs-2 sm-12 :id="`asset-${data?.id}`" :="layoutprops" :style="slidestyle" stackable>
        <Picture :config="config" :alt="data?.image?.alt ?? data?.title" stackable-item />
    </rs-griditem>
</template>

<script>
import { ref, computed, watch, onBeforeMount } from 'vue';
import Picture from '@/components/Picture.ce.vue'
import { usePadIndex } from '@/javascript/lib/composables'

export default {
    name: "GalleryTile",
    components: {Picture},
    props: {
        pos: {
            type: String,
            default() {
                return null
            }
        },
        config: {
            type: Object,
            default() {
                return null
            }
        }
    },
    setup(props) {
        const data          = ref(null)
        const image         = ref(null)
        const layoutprops   = ref(null)
        const url           = ref(null)
        const posindex      = computed(() => {
            return usePadIndex(props.pos)
        })
        const showcontent   = computed(() => data.value?.display?.displaycontent)
        const showtitle     = computed(() => data.value?.display?.showtitle)
        const showlinks     = computed(() => data.value?.display?.displaylinks)

        const slidestyle = ref({
            backgroundColor: null
        })

        watch(data, (newvalue, oldvalue) => {
            if (newvalue) {
                layoutprops.value   = {...data.value?.grid ?? {},...data.value?.ratios, ...data.value?.layout}
                image.value         = {...data.value?.image}
                slidestyle.value.backgroundColor = data.value?.display?.backgroundcolor
                url.value = data.value?.links?.shift()
            }
        })

        onBeforeMount(() => {
            if(props.config?.constructor !== Object) {
                data.value = JSON.parse(props.config)
            }else{
                data.value = props.config
            }
        })

        return {
            data,
            layoutprops,
            showtitle,
            showcontent,
            showlinks,
            image,
            posindex,
            slidestyle,
            url
        }
    }
}
</script>

<style lang="scss">
@include foundation();
</style>
