<script>
import { ref, computed, onBeforeMount, createApp } from 'vue'
import { useStore } from '@/javascript/lib/store'
import { uuid } from '@/javascript/lib/utils'
import { getAxiosInstance, axioscacheconfig } from '@/javascript/lib/api'
import Mutator from '@/javascript/lib/mutator'
import MDDialog from '@/components/MDDialog.ce.vue'

export default {
    name: "App",
    components: {MDDialog},
    props: {
        config: {
            type: String,
            default() {
                return null
            }
        }
    },
    setup(props) {
        const context       = ref(null)
        const store         = useStore()
        const threshold     = [0, 0.25, 0, 0.75, 1]
        const observer      = new IntersectionObserver((entries) => {
            entries.map((entry) => {
                window.requestAnimationFrame(() => {
                    entry.target.toggleAttribute('in-viewport', entry.isIntersecting)
                })
            })
        }, {threshold : threshold})
        store.observer = observer
        context.value = window

        // ------------------------------------------------------------------------------------------ //
        // FORM HANDLERS
        // ------------------------------------------------------------------------------------------ //
        const formSetup = (f) => {
            if (f instanceof HTMLFormElement) {
                const required = [...f.querySelectorAll('.required')]
                if (required?.length) {
                    f.removeAttribute('novalidate')
                    required?.map(r => r.setAttribute('required', 'required'))
                }

                f.addEventListener('submit', (e) => {
                    e.preventDefault()
                    e.stopImmediatePropagation()
                    const data      = new FormData(f)
                    const payload   = {data: {...Object.fromEntries(data) ?? {}}}
                    const api       = getAxiosInstance()
                    const endpoint  = f.getAttribute('action')
                    const headers   = {
                        headers: {
                            'Content-Type': 'application/json;charset=UTF-8',
                            'Access-Control-Allow-Origin': '*',
                            'Accept': 'application/json',
                            'X-Requested-With': 'XMLHttpRequest',
                        }
                    }
                    api.post(endpoint, payload, headers).then( response => {
                        if ([200, 201].includes(response.status)) {
                            console.log('RESPONSE', response)
                            showDialog({type: 'thankyou', selector: 'thankyou'})
                        }
                    }).catch(error => {
                        console.log(error, error.response)
                    })
                })
            }
        }

        const m = new Mutator({
            selector: 'form[enhanced]',
            callback: (e) => {
                e.forEach((v) => {
                    formSetup(v)
                })
            }
        })

        // ------------------------------------------------------------------------------------------ //
        // CLICK HANDLERS
        // ------------------------------------------------------------------------------------------ //
        const inst = ref(null)
        const getDialog = (config) => {
            const template  = document.createElement('template')
            const cfg = {
                id: uuid(),
                title: 'Discover Surrey, BC',
                type: 'generic',
                ...config ?? {}
            }

            let instance    = createApp(MDDialog, {config: cfg}).mount(document.createElement('template'))
            instance = instance.$el.cloneNode(true)
            switch (cfg.type) {
                case 'generic':
                    let tmp = [...document.querySelectorAll(`template[${config?.selector}]`)]?.shift()
                    instance.querySelector('dialogcontent').innerHTML = (tmp instanceof HTMLTemplateElement) ? tmp?.content.querySelector('#mc_embed_shell')?.outerHTML : '';
                    break;
                case 'thankyou':
                    instance.querySelector('dialogcontent').innerHTML = 'Thank you for subscribing!';
                    break;
            }
            template.innerHTML = instance.cloneNode(true).outerHTML
            instance = null
            return template.content.querySelector('dialog')
        }

        const showDialog = (config) => {
            resetDialogs()

            store.dialoginstance = getDialog({...config ?? {}})
            if (store.dialoginstance instanceof HTMLDialogElement) {
                inst.value = store.dialoginstance.cloneNode(true)
                inst.value?.setAttribute('mcsubscribe', '')
                inst.value?.setAttribute(config?.selector, '')
                document.body.append(inst.value)
                inst.value?.showModal()
            }
        }

        context.value?.addEventListener('click', (e) => {
            const dom       = [...e?.composedPath() ?? []]
            const el        = dom.shift()
            const classes   = el instanceof HTMLElement ? [...el?.classList ?? []] : null
            const allowed   = ['subscribepartner', 'subscribenewsletter']
            const c         = (classes?.filter( v => allowed.includes(v)))

            if (!!c?.length) {
                e.preventDefault()
                e.stopImmediatePropagation()
                const type = c?.shift()
                let selector = 'mcpartnerform'
                switch (type) {
                    case 'subscribenewsletter':
                        selector = 'mcsubscribeform'
                        break;
                    case 'subscribepartner':
                    default:
                        selector = 'mcpartnerform'
                        break;
                }

                showDialog({type: 'generic', selector: selector})
            }

            if (classes?.includes('dialog-close')) {
                e.stopImmediatePropagation()
                e.preventDefault()
                const dlg = el.closest('dialog')
                if (dlg instanceof HTMLDialogElement) {
                    dlg?.close()
                } else {
                    inst.value?.close()
                }

                resetDialogs()
            }
        })

        const resetDialogs = () => {
            [...document.querySelectorAll('dialog')]?.map( v => v.remove() )
            store.dialoginstance = null
            inst.value = null
        }

        // ------------------------------------------------------------------------------------------ //
        // SERPS
        // ------------------------------------------------------------------------------------------ //
        const tabpagecache  = new Map()
        const tabcache      = new Map()
        const serptabs = (e) => {
            const tabs = [...e?.querySelectorAll('tab')]
            tabs?.map((v) => {
                const key = v?.getAttribute('key')
                if (!tabcache.has(key)) {
                    tabcache.set(key, v)
                }
            })

            const firsttab = [...tabcache.values()]?.shift()
            const firstgroup = [...tabpagecache.values()].shift()
            window.requestAnimationFrame(() => {
                firsttab.setAttribute('selected', '')
                firstgroup.setAttribute('selected', '')
            })

            e?.addEventListener('click', (evt) => {
                evt.preventDefault();
                evt.stopImmediatePropagation();
                const tab = evt.target;
                if (tab.nodeName.toLowerCase() == 'tab') {
                    const key = tab.getAttribute('key')
                    let target = null;
                    if (!!key) {
                        if (!tabpagecache.has(key)) {
                            target = document.querySelector(`serpgroup[key="${key}"]`)
                            tabpagecache.set(key, target)
                        } else {
                            target = tabpagecache.get(key)
                        }
                        resetTabState(tab, target)
                    }
                }
            })
        }

        const resetTabState = (tab, target) => {
            const tabs = [...tabcache.values()]
            const tabgroups = [...tabpagecache.values()]

            window.requestAnimationFrame(() => {
                tabs?.map( v => v.removeAttribute('selected') )
                tabgroups?.map( v => v.removeAttribute('selected') )

                // Set selected state
                tab.setAttribute('selected', '')
                target.setAttribute('selected', '')
            })

        }

        const tabgroups = new Mutator({
            selector: 'serpgroup',
            callback: (e) => {
                e.forEach((v) => {
                    const key = v.getAttribute('key')
                    if (!tabpagecache.has(key)) {
                        tabpagecache.set(key, v)
                    }
                })
            }
        })

        const tabsmutator = new Mutator({
            selector: 'serptabs',
            callback: (e) => {
                e.forEach((v) => {
                    serptabs(v)
                })
            }
        })

        return {
        }
    }
}
</script>
