<template>
    <Suspense>
        <template #default>
            <rs-container ref="root" rs-elemental="uielementalcrowdriff" :="layoutprops">
                <rs-grid  xxs-1 sm-12 span-xs-12 ref="grid">
                    <rs-griditem v-if="showtitle || showcontent || showlinks" content>
                        <rs-heading v-if="showtitle">
                            <h2 heading ref="heading" scale="lg" weight="regular" casing="display-sm" gutters="0" caps v-html="wrappedtitle"></h2>
                        </rs-heading>
                        <rs-content v-if="showcontent" v-html="data?.content"></rs-content>
                        <rs-actions v-if="showlinks">
                            <MDButton v-for="(item, index) in data?.links" tag="a" :href="item?.url" :target="item?.openinnewwindow ? '_blank' : null" :key="item?.key" :index="index" :label="item?.title" :type="index == 0 ? 'contained' : 'outlined'" :class="item?.css" chunky outlined solid />
                        </rs-actions>
                    </rs-griditem>
                    <rs-griditem stackable media ref="crcontainer" v-if="resourceid" :="mediaprops">
                        <slot></slot>
                    </rs-griditem>
                    <MDSkeleton v-else aspect-3-1 error="true" spin />
                </rs-grid>
            </rs-container>
        </template>
        <template #fallback>
            <MDSkeleton aspect-21-9>
                <MDBlocker active="true" cursor="progress" type="default" />
            </MDSkeleton>
        </template>
    </Suspense>
</template>

<script>
import { ref, computed, watch, onBeforeMount } from 'vue'
import { useStore } from '@/javascript/lib/store'
import { useWrappedString, useComponentdata, useNormalizeLayoutProps, useNormalizeRatioProps } from '@/javascript/lib/composables'
import Picture from '@/components/Picture.ce.vue'
import MDSkeleton from '@/components/MDSkeleton.ce.vue'
import MDButton from '@/components/MDButton.ce.vue'
import MDBlocker from '@/components/MDBlocker.vue'

export default {
    name: "UIElementalCrowdriff",
    components: {Picture, MDButton, MDBlocker, MDSkeleton},
    props: {
        info: {
            type: String,
            default() {
                return null
            }
        },
        config: {
            type: Object,
            default() {
                return null
            }
        }
    },
    setup(props) {
        const store         = useStore()
        const root          = ref(null)
        const crcontainer   = ref(null)
        const heading       = ref(null)
        const __to          = ref(null)

        const data          = ref(null)
        const resourceid    = ref(null)
        const contentprops  = ref(null)
        const mediaprops    = ref(null)
        const grid          = ref(null)
        const layoutprops   = ref({})
        const wrappedtitle  = computed(() => {
            return useWrappedString(data.value?.title, [...data.value?.focustext] ?? [])
        })
        const showcontent       = computed(() => !!data.value?.display?.displaycontent && !!data.value?.content)
        const showlinks         = computed(() => !!data.value?.display?.displaylinks && !!data.value?.links?.length)
        const showtitle         = computed(() => !!data.value?.display?.showtitle && !!data.value?.title)

        watch(root, (newvalue, oldvalue) => {
            if(newvalue instanceof HTMLElement) {
                store.observe(newvalue)
            }
        })


        watch(data, (newvalue, oldvalue) => {
            const {layout} = useNormalizeLayoutProps(data)
            layoutprops.value = {...layout.value}

            const {ratios} = useNormalizeRatioProps(data)
            mediaprops.value = {...ratios.value}

            // Content properties
            contentprops.value  = contentprops.value ?? {}
            data.value?.layout?.contentprops?.map( v => {
                if (v.key == 'resourceid') {
                    resourceid.value = v.value
                } else {
                    contentprops.value[v.key] = v.value
                }
            })

            if(!!data.value?.grid && (grid.value instanceof HTMLElement)) {
                if ('gap' in data.value?.grid) {
                    const attr = data.value?.grid.gap;
                    if (!!attr && grid.value instanceof HTMLElement) {
                        grid.value?.setAttribute(attr, '')
                    }
                }
            }

            // Add "standalone" attribute content fields are hidden
            if (!showcontent.value && !showtitle.value && grid.value instanceof HTMLElement) {
                grid.value?.setAttribute('standalone', '')
            }
        })

        // Lifecycle hooks

        onBeforeMount(() => {
            const params = props.info ? JSON.parse(props?.info) : null

            if (params?.constructor == Object && !!params?.prefetch) {
                useComponentdata({...params, query: 'readCrowdriffComponent', data: data})
            }else{
                if(!!props.config) {
                    data.value = (JSON.parse(props.config))?.props
                }
            }
        })

        return {
            root,
            crcontainer,
            grid,
            heading,
            data,
            showcontent,
            showlinks,
            showtitle,
            wrappedtitle,
            contentprops,
            layoutprops,
            mediaprops,
            resourceid
        }
    }
}
</script>

<style lang="scss">
@include foundation();
</style>
