<template>
    <Suspense>
        <template #default>
            <rs-container ref="root" rs-elemental="uielementalfeaturemap" :="layoutprops">
                <rs-grid class="component-wrapper" xxs-1 sm-12 span-xs-6 ref="grid" :="gridprops">
                    <rs-griditem :="gridColumn" v-if="showtitle || showcontent || showlinks" content>
                        <rs-heading v-if="showtitle">
                            <h2 weight="regular" casing="display-sm" gutters="0" caps :focustext="focustext" v-html="wrappedtitle"></h2>
                            <h3 v-if="data?.subtitle" role="doc-subtitle" scale="subtitle-mm" weight="900" gutters="0" caps v-html="data?.subtitle"></h3>
                        </rs-heading>
                        <rs-content v-if="showcontent" :="contentprops" v-html="data.content" scale="subtitle-mm">
                        </rs-content>
                        <rs-actions v-if="showlinks">
                            <MDButton v-for="(item, index) in data?.links" tag="a" :href="item?.url" :target="item?.openinnewwindow ? '_blank' : null" :key="item?.key" :index="index" :label="item?.title" :class="item?.css" solid contained rounded />
                        </rs-actions>
                    </rs-griditem>
                    <rs-griditem v-bind="{ ...gridColumn, ...ratioprops }" media>
                        <rs-grid  xxs-1 sm-12 span-xs-12 ref="grid" :="gridprops" stackable>
                            <rs-griditem span-xs-1 span-sm-12 controlsmessage stackable-item easing :="beforewheel">
                                <p scale="subtitle-lg" gutters="0" v-html="zoommessage" />
                            </rs-griditem>
                            <rs-griditem span-xs-1 span-sm-12 svgmapcontrols stackable-item>
                                <controls ref="controls">
                                    <button noappearance ref="controlprev" zoomin aria-label="Zoom In" class="zooomcontrol-in" stackable>
                                        <icon class="md-icon" easing stackable-item>add</icon>
                                    </button>
                                    <button noappearance ref="controlnext" zoomout aria-label="Zoom Out" class="zooomcontrol-out" stackable>
                                        <icon class="md-icon" easing stackable-item>remove</icon>
                                    </button>
                                </controls>
                            </rs-griditem>
                            <rs-griditem span-xs-1 span-sm-12 svgmapcontainer stackable-item>
                                <svgmaproot>
                                    <MapSVG ref="zoomtarget" :="viewbox" :symbols="symbols" preserveAspectRatio="xMaxYMin slice"></MapSVG>
                                </svgmaproot>
                            </rs-griditem>
                            <rs-griditem span-xs-1 span-sm-12 svgmapcontent stackable-item easing :="active">
                                <contentwrapper ref="svgcontentcontainer">
                                    <heading v-if="selectedcontent?.title">
                                        <h2 casing="display-sm" v-html="selectedcontent?.title"></h2>
                                    </heading>
                                    <content scale="body-sm" v-if="selectedcontent?.content !== null" v-html="selectedcontent?.content"></content>
                                    <actions v-if="selectedcontent?.links?.length">
                                        <MDButton v-for="(item, index) in selectedcontent?.links" tag="a" :href="item?.url" :target="item?.openinnewwindow ? '_blank' : null" :key="item?.key" :index="index" :label="`${item?.title ?? 'Learn More'}`" :type="index == 0 ? 'contained' : 'outlined'" contained solid />
                                    </actions>
                                    <button close noapperance><icon mdicon>close</icon></button>
                                </contentwrapper>
                            </rs-griditem>
                </rs-grid>
                    </rs-griditem>
                </rs-grid>
            </rs-container>
        </template>
        <template #fallback>
            <MDSkeleton aspect-21-9>
                <MDBlocker active="true" cursor="progress" type="default" />
            </MDSkeleton>
        </template>
    </Suspense>
</template>

<script>
import { ref, computed, watch, reactive, onBeforeMount, onMounted } from 'vue';
import { useWrappedString , useComponentdata, useNormalizeComponentProps, useNormalizeLayoutProps, useNormalizeGridProps, useNormalizeRatioProps } from '@/javascript/lib/composables'
import { useStore } from '@/javascript/lib/store'
import panzoom from 'panzoom'
import MDButton from '@/components/MDButton.ce.vue'
import MDBlocker from '@/components/MDBlocker.vue'
import MDSkeleton from '@/components/MDSkeleton.ce.vue'
import MapSVG from '@/components/MapSVG.ce.vue'

export default {
    name: "UIElementalFeatureMap",
    components: {MDButton, MDSkeleton, MDBlocker, MapSVG},
    props: {
        info: {
            type: String,
            default() {
                return null
            }
        },
        config: {
            type: Object,
            default() {
                return null
            }
        }
    },
    setup(props) {
        const root              = ref(null)
        const store             = useStore()
        const data              = ref(null)
        const gridColumn    = ref(null)
        const zoomtarget        = ref(null)
        const zoomroot          = ref(null)
        const controls          = ref(null)
        const instance          = ref(null)
        const active            = ref(null)
        const beforewheel       = ref({active: null})
        const os                = ref(null)
        const zoommessage       = ref(null)
        const messageduration   = ref(5)
        const selectedcontent   = ref(null)
        const svgcontentcontainer   = ref(null)
        const viewbox           = ref({
            viewBox: "0 0 720 1024"
        })
        const symbols           = ref(null)
        const layoutprops       = ref(null)
        const ratioprops        = ref(null)
        const contentprops      = ref(null)
        const gridprops         = ref(null)
        const grid              = ref(null)
        const showcontent       = computed(() => data.value?.display?.displaycontent)
        const showtitle         = computed(() => data.value?.display?.showtitle)
        const showlinks         = computed(() => data.value?.display?.displaylinks && data.value?.links?.length)
        const wrappedtitle      = computed(() => {
            return useWrappedString(data.value?.title, [...data.value?.focustext] ?? [])
        })

        const getContainerRect = () => {
            const el = root.value.querySelector('svgmaproot')
            zoomroot.value = !zoomroot.value ? el : zoomroot.value
            let cx = null
            let cy = null

            if (zoomroot.value instanceof HTMLElement) {
                const rect = zoomroot.value?.getBoundingClientRect()

                cx = rect.x + rect.width * .5
                cy = rect.y + rect.height * .5
            }

            return {cx, cy}
        }

        /**
         * Monitor changes to store.svgmapdata
         */
        watch(() => store.svgmapdata, (n, o) => {
            active.value = (!!store.svgmapdata) ? {active: ''} : null
            if (!!store.svgmapdata) {
                selectedcontent.value = store.svgmapdata
            }
        })

        watch(() => svgcontentcontainer.value, (n, o) => {
            if (n) {
                if (svgcontentcontainer.value instanceof HTMLElement) {
                    svgcontentcontainer.value?.addEventListener('click', (e) => {
                        const el = e.target
                        if (el instanceof HTMLButtonElement) {
                            store.svgmapdata = null
                        }
                    })
                }
            }
        })

        watch(root, (newvalue, oldvalue) => {
            if(newvalue instanceof HTMLElement) {
                store.observe(newvalue)
                let __to = null
                const el = root.value.querySelector('svgmaproot')
                if (!!el) {
                    zoomroot.value = el
                    const options = {
                        smoothScroll: true,
                        maxZoom: 20,
                        minZoom: 1,
                        bounds: true,
                        boundsPadding: 1,
                        beforeWheel: (e) => {
                            beforewheel.value = !e.altKey ? {active: ''} : {active: null}
                            __to = (!e.altKey) ? setTimeout(() => {
                                beforewheel.value = {active: null}
                                clearTimeout(__to)
                            }, 1000 * messageduration.value) : null;
                            return !e.altKey
                        }
                    }
                    instance.value = new panzoom(el, options)
                    if (instance.value) {
                        instance.value?.zoomAbs(0, 0, 1)
                    }
                }
            }
        })

        watch(controls, (n, o) => {
            if (n instanceof HTMLElement) {
                controls.value?.addEventListener('click', (e) => {
                    e.preventDefault()
                    const el        = e.target
                    const {cx, cy}  = getContainerRect()
                    const factor    = el.hasAttribute('zoomin') ? 1.5 : 0.5
                    if (instance.value) {
                        instance.value.smoothZoom(cx, cy, factor)
                    }
                })
            }
        })

        watch(data, (newvalue, oldvalue) => {
            if (newvalue) {
                const {props}       = useNormalizeComponentProps(data)
                const {layout}      = useNormalizeLayoutProps(data)
                const {ratios}      = useNormalizeRatioProps(data)
                const {grid, gap}   = useNormalizeGridProps(data)

                // Extract the ratios as a value object, but DON'T combine with `layoutprops`
                ratioprops.value    = {...ratios.value}
                layoutprops.value   = {...layout.value, ...grid.value, ...props.value}

                // layoutprops.value   = {...props.value}
                const r = new RegExp('(^[0-9]+)')
                if (!!layoutprops.value?.label && !(r.test(layoutprops?.value?.label))) {
                    layoutprops.value[layoutprops.value.label] = ''
                    delete layoutprops.value.label
                }

                if (data.value?.layout?.contentprops) {
                    contentprops.value = {}
                    data.value?.layout?.contentprops?.map( v => {
                        contentprops.value[v.key] = v.value
                    });
                }

                symbols.value = [...newvalue?.components ?? []]

                gridColumn.value = {
                    'span-xs-1' : '',
                    'span-sm-12' : ''
                }

                if(showtitle.value || showcontent.value || showlinks.value) {
                    gridColumn.value = {
                        'span-xs-1' : '',
                        'span-sm-6' : ''
                    }
                }
            }
        })

        onBeforeMount(() => {
            const params = props.info ? JSON.parse(props?.info) : null
            if (params?.constructor == Object && !!params?.prefetch) {
                useComponentdata({...params, query: 'readSymbolCollection', data: data})
            }else{
                if(!!props.config) {
                    data.value = (JSON.parse(props.config))?.props
                }
            }

            os.value = navigator.userAgentData.platform ?? 'Unknown OS'
            const key = (os.value == 'macOS') ? 'Option' : 'Alt'
            zoommessage.value = `Use ${key} Key + Mouse Wheel to zoom`
        })

        return {
            root,
            data,
            active,
            selectedcontent,
            svgcontentcontainer,
            grid,
            gridprops,
            layoutprops,
            ratioprops,
            contentprops,
            showtitle,
            showcontent,
            showlinks,
            wrappedtitle,
            zoomtarget,
            viewbox,
            symbols,
            controls,
            beforewheel,
            zoommessage,
            gridColumn
        }
    }
}
</script>

<style lang="scss">
@include foundation();
</style>
