<template>
    <Suspense>
        <template #default>
            <div ref="root" v-if="data?.image?.id" rs-elemental="uielementalimage" stackable>
                <rs-media v-if="image?.url && data?.image?.sources" :="layoutprops">
                    <Picture :config="data?.image" :alt="data?.image?.alt ?? data?.title" :="style" />
                </rs-media>
            </div>
            <MDSkeleton v-else aspect-16-9 error="true" spin />
        </template>
        <template #fallback>
            <MDSkeleton aspect-16-9>
                <MDBlocker active="true" cursor="progress" type="default" />
            </MDSkeleton>
        </template>
    </Suspense>
</template>

<script>
import { ref, computed, watch, onBeforeMount } from 'vue'
import { useComponentdata, useNormalizeComponentProps } from '@/javascript/lib/composables'
import Picture from '@/components/Picture.ce.vue'
import MDSkeleton from '@/components/MDSkeleton.ce.vue'
import MDBlocker from '@/components/MDBlocker.vue'
import { useStore } from '@/javascript/lib/store'

export default {
    name: "UIElementalImage",
    components: {Picture, MDBlocker, MDSkeleton},
    props: {
        info: {
            type: String,
            default() {
                return null
            }
        },
        config: {
            type: Object,
            default() {
                return null
            }
        }
    },
    setup(props) {
        const root          = ref(null)
        const store         = useStore()
        const data          = ref(null)
        const image         = ref(null)
        const layoutprops   = ref({})
        const styleprops    = ref({})
        const style         = computed(() => {
            const p = Object.values(styleprops.value)
            const s = []
            p.map((v) => {
                if (v.key !== 'background-color') {
                    s.push(`${v.key}: ${v.value}`)
                }
            })
            return {
                style: `${s.join('; ')}`
            }
        })
        const bgstyle         = computed(() => {
            const p = Object.values(styleprops.value)
            const s = []
            p.map((v) => {
                if (v.key === 'background-color') {
                    s.push(`${v.key}: ${v.value}`)
                }
            })
            return {
                style: `${s.join('; ')}`
            }
        })

        watch(root, (newvalue, oldvalue) => {
            if(newvalue instanceof HTMLElement) {
                store.observe(newvalue)
            }
        })

        watch(data, (newvalue, oldvalue) => {
            const {props}           = useNormalizeComponentProps(data)
            layoutprops.value   = {...props.value}
            image.value         = data.value?.image
        })

        onBeforeMount(() => {
            const params = props.info ? JSON.parse(props?.info) : null

            if (params?.constructor == Object && !!params?.prefetch) {
                useComponentdata({...params, query: 'readComponent', data: data})
            }else{
                if(!!props.config) {
                    data.value = (JSON.parse(props.config))?.props
                }
            }
        })


        return {
            root,
            data,
            layoutprops,
            image,
            bgstyle,
            style
        }
    }
}
</script>

<style lang="scss">
@include foundation();
</style>
