<template>
    <dialog gallery v-if="active()" ref="dialog">
        <dialogheading class="dialog-heading"><MDText v-if="!!label" :label="label" scale="subtitle1"/></dialogheading>
        <dialogcontent class="dialog-content" v-html="content?.outerHTML" />
        <button class="dialog-close" @click="close"><icon class="md-icon">close</icon></button>
    </dialog>
</template>

<script>
import { ref, computed } from 'vue'
import MDText from '@/components/MDText.vue';

export default {
    name: "MDGalleryDialog",
    components: {MDText},
    props: {
        label: {
            type: String,
            default() {
                return null;
            }
        },
        content: {
            type: HTMLElement,
            default() {
                return null;
            }
        }
    },
    setup( props ) {
        const dialog = ref(null)
        const active = computed(() => {
            return () => {
                if(!!props.content) {
                    dialog.value?.showModal()
                }else{
                    dialog.value?.close()
                }
                return props.content ?? null
            }
        })

        const close = (e) => {
            dialog.value?.close()
        }

        return {
            active,
            dialog,
            close
        }
    }
}
</script>
