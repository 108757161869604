<template>
    <a ref="root" :href="`#${data.urlsegment}`" class="listcard listcard--wrapper">
        <span class="listcard--media">
            <img v-if="data.asset?.id" :src="data.asset.mm" :alt="data.name" loading="lazy" />
        </span>
        <span class="listcard--details">
            <MDText v-if="!!data.name" :label="data.name" v-text="data.name" scale="body1" />
        </span>
    </a>
</template>

<script>
import { ref, onBeforeMount, watch, computed } from 'vue';
import MDText from '@/components/MDText.vue';
import FPOImage from '@/assets/fpo.svg?url'

export default {
    name: "Category",
    components: {MDText, FPOImage},
    props: {
        config: {
            type: Object,
            default() {
                return null
            }
        }
    },
    setup(props) {
        const root = ref(null)
        const data = ref(null)
        const desc = computed(() => (data.description?.length > 12) ? `${data.description?.slice(0, 12)}…` : data.description)
        const getFPO = computed(() => {
            const uri = new URL(FPOImage, import.meta.url)
            return uri?.toString()
        })

        onBeforeMount(() => {
            data.value = props.config
        })

        return {
            root,
            data,
            desc,
            getFPO
        }
    }
}
</script>

<style lang="scss">
@include foundation();
</style>
