<template>
    <a :href="`${config.URL ?? '#'}`" :key="config.key" :title="config.Title" :aria-label="config.Title" target="_blank" rel="external noreferrer noopner" :class="cssclass" elastic>
        <span class="md-sociallinks--label">{{ config.Title }}</span>
    </a>
</template>

<script>
import { computed, onBeforeMount } from 'vue';

export default {
    name: "SocialNavItem",
    components: {},
    props: {
        config: {
            type: Object,
            default() {
                return null
            }
        }
    },
    setup(props) {
        const cssclass = computed(() => {
            return `md-sociallinks--link md-social--link-${props.config.Key} socicon-${props.config.Key} socicon-${props.config.Key}--branded`
        })
        onBeforeMount(() => {
        })
        return {
            cssclass
        }
    }
}
</script>
