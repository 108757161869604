<template>
    <Suspense>
        <template #default>
            <rs-container ref="root" rs-elemental="uielementalgrid" :="layoutprops">
                <rs-grid  xxs-1 sm-12 gap-24 ref="grid" :="gridprops">
                    <rs-griditem :="gridColumn" media>
                        <!-- <Picture :config="image" :alt="image?.alt ?? data?.title" :="style" /> -->
                         <GridColumn v-for="(item, index) in gridItems" :ref="`item-${index}`" :config="item"/>
                    </rs-griditem>
                    <rs-griditem :="gridColumn" v-if="showtitle || showcontent || showlinks" content>
                        <rs-heading v-if="showtitle">
                            <h2 weight="regular" casing="display-sm" gutters="0" caps v-html="wrappedtitle"></h2>
                            <h3 v-if="data?.subtitle" role="doc-subtitle" scale="subtitle-mm" weight="900" gutters="0" caps v-html="data?.subtitle"></h3>
                        </rs-heading>
                        <rs-content v-if="showcontent" :="contentprops" v-html="data.content" scale="subtitle-mm">
                        </rs-content>
                        <rs-actions v-if="showlinks">
                            <MDButton v-for="(item, index) in data?.links" tag="a" :href="item?.url" :target="item?.openinnewwindow ? '_blank' : null" :key="item?.key" :index="index" :label="item?.title" :class="item?.css" solid contained rounded />
                        </rs-actions>
                    </rs-griditem>
                </rs-grid>
            </rs-container>
        </template>
    </Suspense>
</template>
<script>
import { ref, computed, watch, onBeforeMount } from 'vue';
import { useWrappedString , useComponentdata, useNormalizeLayoutProps, useNormalizeGridProps, useNormalizeRatioProps } from '@/javascript/lib/composables'
import MDButton from '@/components/MDButton.ce.vue'
import MDBlocker from '@/components/MDBlocker.vue'
import MDSkeleton from '@/components/MDSkeleton.ce.vue'
import Picture from '@/components/Picture.ce.vue'
import { useStore } from '@/javascript/lib/store'
import GridColumn from '@/components/GridColumn.vue';

export default {
    name: "UIElementalGrid",
    components: {MDButton, MDSkeleton, MDBlocker, Picture, GridColumn},
    props: {
        info: {
            type: String,
            default() {
                return null
            }
        },
        config: {
            type: Object,
            default() {
                return null
            }
        }
    },
    setup(props) {
        const root          = ref(null)
        const store         = useStore()
        const data          = ref(null)
        const layoutprops   = ref(null)
        const ratioprops    = ref(null)
        const contentprops  = ref(null)
        const gridprops     = ref(null)
        const grid          = ref(null)
        const image         = ref(null)
        const gridColumn    = ref(null)
        const gridItems     = ref(null)
        const showcontent   = computed(() => data.value?.display?.displaycontent)
        const showtitle     = computed(() => data.value?.display?.showtitle)
        const showlinks     = computed(() => data.value?.display?.displaylinks && data.value?.links?.length)
        const wrappedtitle  = computed(() => {
            return useWrappedString(data.value?.title, [...data.value?.focustext] ?? [])
        })

        watch(root, (newvalue, oldvalue) => {
            if(newvalue instanceof HTMLElement) {
                store.observe(newvalue)
            }
        })

        watch(data, (newvalue, oldvalue) => {
            if (newvalue) {
                const {layout}      = useNormalizeLayoutProps(data)
                const {ratios}      = useNormalizeRatioProps(data)
                const {grid, gap}   = useNormalizeGridProps(data)
                // Extract the ratios as a value object, but DON'T combine with `layoutprops`
                ratioprops.value    = {...ratios.value}
                layoutprops.value   = {...layout.value, ...grid.value}

                gridprops.value = {...grid.value}
                if (!!gap.value) {
                    gridprops.value[gap.value] = ''
                }

                image.value = {...data.value?.image}

                gridColumn.value = {
                    'span-xs-1' : '',
                    'span-sm-12' : ''
                }

                if(showtitle.value || showcontent.value || showlinks.value) {
                    gridColumn.value = {
                        'span-xs-1' : '',
                        'span-sm-6' : ''
                    }
                }

                console.log("DATA&component", data.value.components, data.value.components.length)

                gridItems.value = []

                // for(let i = 0; i < data.value.components.length; i++) {
                //     gridItems.value.push(data.value.components[i])
                // }

                gridItems.value = data.value.components.map(component => component)

                console.log("gridItems!!!!", gridItems.value)

                const colsValue = gridItems.value[1].props.grid.cols;
                console.log("colsValue", colsValue)
            }

            console.log("DATA:", data.value)
        })

        onBeforeMount(() => {
            console.log("DATA:", data.value)
            const params = props.info ? JSON.parse(props?.info) : null
            if (params?.constructor == Object && !!params?.prefetch) {
                useComponentdata({...params, query: 'readMosaic', data: data})
            }else{
                if(!!props.config) {
                    data.value = (JSON.parse(props.config))?.props
                }
            }
        })

        return {
            root,
            data,
            grid,
            gridprops,
            layoutprops,
            ratioprops,
            contentprops,
            showtitle,
            showcontent,
            showlinks,
            image,
            wrappedtitle,
            gridColumn,
            gridItems
        }
    }
}
</script>

<style lang="scss">
@include foundation();
</style>
